import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import Carousel from 'react-bootstrap/Carousel';

import { getAllReviews, fetchReviews } from '../services/apiReviewsService';
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function Reviews() {
    const params = useParams();
    const axiosPrivate = useAxiosPrivate();
    const [showReviewsPlaceholder, setShowReviewsPlaceholder] = useState(true);
    const [carouselItems, setCarouselItems] = useState(false);
    const [placeholder, setPlaceholder] = useState(false);

    useEffect(() => {
        getPlaceholders();
        if (!carouselItems.length) {
            // Timeout needs to mimic asynchrone reviews loading
            setTimeout(() => {
                getReviews();
            }, 0);
        }
    }, []);

    let getReviews = async () => {
        setShowReviewsPlaceholder(true);
        let results = await fetchReviews(axiosPrivate);
        console.log("results: ", results)
        results = results?.data
        // use "en" records when there are no records for this lang param
        if (params.lang && results[params.lang]?.length > 0) {
            results = results[params.lang];
        } else {
            results = results["en"];
        }
        let carouselCardsTemp = [];
        let carouselItemCardsTemp = [];
        let carouselItemSlideTemp = [];
        if (results && results.length > 0) {
            for (let i = 0; i < results.length; i++) {

                let carouselCardStarsTemp = [];
                let resultStars = results[i].stars || 5;
                for (let stars = 0; stars < resultStars; stars++) {
                    carouselCardStarsTemp.push(<i key={`review-star-${i}-${stars}`} className="bi bi-star-fill"></i>);
                }

                carouselCardsTemp.push(
                    <div key={"key-review-" + i} className="col-12 col-lg-4 p-4 text-left ">
                        <div className="card card__review">
                            <div className="card-body">
                                <div className="card-title d-flex">
                                    {!!results[i].imgUrl?.length > 0 ?
                                        <img src={results[i].imgUrl ?? "../../../images/icon-person-circle.svg"} alt="avatar" loading="lazy" />
                                        :
                                        <img src="../../../images/icon-person-circle.svg" alt="avatar" />
                                    }
                                    <h5 className="m-2 semi-bold">
                                        {results[i].author}
                                    </h5>
                                </div>
                                <p className="card-text px-2">
                                    {results[i].text}
                                </p>
                                <div className="card-rating py-2 text-center d-flex gap-2 justify-content-center">
                                    {carouselCardStarsTemp}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }

            console.log("carouselCardsTemp: ", carouselCardsTemp)

            for (let i = 0; i < carouselCardsTemp.length; i++) {
                carouselItemCardsTemp.push(carouselCardsTemp[i]);
                if (i % 3 == 2 || window.innerWidth < 992) {
                    carouselItemSlideTemp.push(
                        <Carousel.Item key={"key-review-carousel-item-" + i}>
                            <div className="container-fluid">
                                <div className="row d-flex justify-content-around">
                                    {carouselItemCardsTemp}
                                </div>
                            </div>
                        </Carousel.Item>
                    );
                    carouselItemCardsTemp = [];
                }
            }

            if (carouselItemCardsTemp.length > 0) {
                carouselItemSlideTemp.push(
                    <Carousel.Item key={"key-review-carousel-item-last"}>
                        <div className="container-fluid">
                            <div className="row d-flex justify-content-around">
                                {carouselItemCardsTemp}
                            </div>
                        </div>
                    </Carousel.Item>
                );
            }

            if (carouselItemSlideTemp.length > 0) {
                setCarouselItems(carouselItemSlideTemp);
            }
        }
        setShowReviewsPlaceholder(false);
    }

    let getPlaceholders = async () => {
        let placeholderTemp = [];
        for (let i = 0; i < 3; i++) {
            placeholderTemp.push(
                <div key={"key-review-placeholder-" + i} className="col-12 col-lg-4 p-4 text-left ">
                    <div className="card card__review card__review--placeholder">
                        <div className="card-body">
                            <div className="card-title d-flex">
                                <img src="../../../images/icon-person-circle.svg" alt="avatar" />
                                <div className="card-title__text p-2">
                                    <div className="load-wraper">
                                        <div className="activity"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-text px-2">
                                <div className="load-wraper">
                                    <div className="activity"></div>
                                </div>
                            </div>
                            <div className="card-rating py-2 text-center d-flex gap-2 justify-content-center">
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (placeholderTemp.length > 0) {
            setPlaceholder(placeholderTemp);
        }
    }

    return (<>
        {!!showReviewsPlaceholder ?
            <div className="container-fluid">
                <div className="row d-flex justify-content-around">

                    {placeholder}

                </div>
            </div>
            :
            <div id="carousel-reviews" className="carousel__reviews">
                <Carousel>
                    {carouselItems}
                </Carousel>
            </div>
        }
    </>
    );
}

export default Reviews;