import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import MetaTags from '../utils/MetaTags';

function AirportTransfer() {
    const params = useParams();

    const [transferType, setTransferType] = useState('1-way transfer');
    const [noOfTravellers, setNoOfTravellers] = useState('');
    const [pickupDate, setPickupDate] = useState(toLocalISOString(new Date()));
    const [dropoffLocation, setDropoffLocation] = useState('');
    const [fullName, setFullName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');

    function toLocalISOString(date) {
        const localDate = new Date(date - date.getTimezoneOffset() * 60000);

        // Optionally remove second/millisecond if needed
        localDate.setSeconds(null);
        localDate.setMilliseconds(null);
        return localDate.toISOString().slice(0, -1);
    }

    const description = 'Looking for efficient airport transfers in Mauritius? Contact OLA Mauritius now for hassle-free private and group airport transfer bookings.'
    const ogImage = "/images/transfer_image.webp"
    return (<Fragment>

    <MetaTags title='Mauritius Airport Transfer | Taxi Transfer - OLA Mauritius' url='services/airport-transfer' description={description} ogDescription={description} ogImage={ogImage} />

    <div className="ola-old-pages">
        <section className="internal-header">

            <div className="container">
                <div className="row v-align-children">
                    <div className="col-md-8 bold">
                        <h1>
                            {
                                params.lang == "fr" ?
                                    "Transfert Aéroport"
                                    :
                                    "Airport Transfer"
                            }
                        </h1>
                    </div>

                    <div className="col-md-4 d-none d-md-block">
                        <ol className="breadcrumb float-end semi-bold">
                            <li>
                                <a href={params.lang ? `/${params.lang}/` : `en/`}>
                                    {
                                        params.lang == "fr" ?
                                            "Accueil"
                                            :
                                            "Home"
                                    }
                                </a>
                            </li>
                            <li className="current">
                                {
                                    params.lang == "fr" ?
                                        "Transfert Aéroport"
                                        :
                                        "Airport Transfer"
                                }
                            </li>
                        </ol>
                    </div>

                </div>
            </div>
        </section>


        <div className="container mt-2 mb-5">

            <div className="row">

                {
                    params.lang == "fr" ?
                        <div className="col-lg-7">
                            <p>
                                <img className="mobile-block float-end" src="/images/transfer_img.webp" alt="airport transfer services" width="250" height="377.66" />
                                Nous comprenons parfaitement que l’arrivée dans un nouveau pays peut être une expérience impressionnante, surtout pour ceux qui voyagent pour la première fois à Maurice.C’est là qu’OLA Mauritius intervient pour faciliter votre transition et vous aider à vous sentir à l’aise dès votre arrivée sur cette île paradisiaque.
                            </p>
                            <p>OLA Mauritius a mis en place des services de transferts d’aéroport spécialisés à Maurice. Ces services vous permettent de vous déplacer facilement de l’aéroport international Sir Seewoosagur Ramgoolam (SSR) de Plaisance à votre hôtel à travers l’île Maurice, et vice versa.</p>
                            <p>Cela signifie moins de stress pour vous et plus de temps pour profiter de la beauté et de l’hospitalité de Maurice.</p>
                            <p>Que vous visitiez Maurice pour ses plages idylliques, ses paysages pittoresques ou sa culture riche, laissez OLA Mauritius s’occuper de vos besoins de transport. Avec nos services de transferts d’aéroport à Maurice, votre voyage commence et se termine en toute sérénité.</p>
                            <p>Profitez du meilleur de ce que Maurice a à offrir, sans les tracas liés aux transports. Réservez votre transfert d’aéroport avec OLA Mauritius aujourd’hui et faites de votre voyage à Maurice une expérience inoubliable.</p>
                            <h2 className="text-secondary">Service taxi (Navette aéroport)</h2>
                            <p>Réservez un taxi ou une navette en ligne avec nous chez OLA Mauritius, et nous serons là pour vous accueillir à l’aéroport de Maurice, prêts à vous emmener à votre destination choisie sur l’île. Bien sûr, le trajet retour est également possible – nous pouvons vous chercher n’importe où à Maurice et vous ramener à l’aéroport à temps pour votre vol.</p>
                            <p>Même si votre vol à destination de Maurice est retardé, il n’y a pas de souci à se faire.</p>
                            <p>Nous suivons tous les vols de près pour nous assurer que votre taxi ou navette OLA Mauritius est toujours prêt et en attente, peu importe quand vous atterrissez. Votre confort et votre commodité à Maurice sont notre priorité absolue !</p>
                            <ul>
                                <li><strong>Recommandé pour:</strong> jusqu’à 4 personnes.</li>
                                <li><strong>Franchise de bagages:</strong> 1 grande valise et 2 bagages à main.</li>
                            </ul>
                            <h2 className="text-secondary">Taxi/Navette aéroport collectif (transferts de groupe)</h2>
                            <p>Vous êtes un groupe de plus de 4 personnes, nous avons des solutions sur mesure pour vous grâce à notre service de WeCab. Profitez de nos prix compétitifs et de notre excellent service et arrivez à destination dans la bonne humeur avec vos proches.</p>
                            <ul>
                                <li><strong>Recommandé pour:</strong> plus de 4 personnes.</li>
                                <li><strong>Franchise de bagages:</strong> 4 grande valise et 6 bagages à main.</li>
                            </ul>
                            <h2 className="text-secondary">Les meilleures offres de location de voiture à l’aéroport de Maurice</h2>
                            <p>Chez OLA Mauritius, nous nous spécialisons dans l’offre de la meilleure expérience de location voiture ile maurice aéroport. Nous comprenons que votre temps est précieux, c’est pourquoi nous facilitons la prise en charge et le retour de votre voiture directement à l’aéroport de Maurice.</p>
                            <p>Nous proposons des tarifs compétitifs sur une large gamme de véhicules de qualité, allant des compactes économiques aux SUV de luxe. Quelle que soit l’occasion, nous avons la voiture à l’aéroport qui répondra à vos besoins et à votre budget.</p>
                            <p>Mais nous ne nous arrêtons pas là. Nous savons que louer une voiture dans un pays étranger peut être une expérience stressante. C’est pourquoi notre équipe dédiée est disponible 24h/24 et 7j/7 pour vous aider avec votre location voiture aéroport ile maurice. Que vous ayez des questions sur votre réservation ou besoin d’aide sur la route, nous sommes là pour vous.</p>
                            <p>Donc, si vous cherchez les meilleures offres de location de voiture à l’aéroport de Maurice, ne cherchez pas plus loin. Réservez votre voiture de location avec OLA Mauritius dès aujourd’hui et commencez votre aventure à Maurice en toute sérénité.</p>
                        </div>
                        :
                        <div className="col-lg-7">
                            <img className="mobile-block" src="/images/transfer_img.webp" alt="airport transfer services" width="250" height="377.66" /><br />
                            We understand that the logistic aspect of any holiday can be daunting, especially for first-time travelers. OLA Mauritius, has put together two <strong>Mauritius Airport Transfer services</strong> allowing you to easily get around from SSR International Airport to your hotel or back to the airport. We are cheaper by at least 20% than “stand by” Airport taxi !<p></p>
                            <h2 className="text-secondary">Private Airport Transfer</h2>
                            <p>Book a taxi &nbsp;and we’ll pick you up at the airport and drop you at the address of your choice. We also offer pickup from your hotel or any custom location to the airport. Worried about delayed flights – don’t worry, we’ll wait for you.</p>
                            <ul>
                                <li><strong>Recommended for:</strong> Up to 4 persons.</li>
                                <li><strong>Baggage allowance:</strong> 1 large suitcase and 2 hand luggage.</li>
                            </ul>
                            <h2 className="text-secondary">Group Airport Transfer</h2>
                            <p>You are a group of more than 4 persons ? We have a tailor made solution for you with our WeCab service. Take advantage of our most competitive price and service and reach your destination with fun together with your dear ones.</p>
                            <ul>
                                <li><strong>Recommended for:</strong>&nbsp;4 persons or more.</li>
                                <li><strong>Baggage allowance:</strong> 4 large suitcases and 6 hand luggage.</li>
                            </ul>
                            <h2 className="text-secondary">Why You Should Get An Airport Transfer With OLA Mauritius</h2>
                            <p>Kickstart your Mauritian adventure stress-free with a Mauritius airport transfer from OLA Mauritius, the island’s leading car rental and transfer service. Say goodbye to the hassle of navigating unfamiliar public transport or haggling with local taxi services.</p>
                            <p>Choose OLA Mauritius for a smooth, comfortable journey from the airport to your accommodation. Our professional drivers are prompt, courteous, and knowledgeable about the local routes, ensuring your journey is quick and efficient. Relax in the comfort of our modern, well-maintained vehicles after your long flight and soak in your first impressions of this paradise island.</p>
                            <p>Booking your airport transfer with us is a breeze, thanks to our user-friendly online platform. Plus, benefit from at least 20-30% savings on all online bookings, offering you exceptional value right from the start.</p>
                            <p>Customer satisfaction is our top priority, and our team is constantly available to assist you with any needs or inquiries.</p>
                            <p>Arrive in style and ease into your Mauritian experience with OLA Mauritius. We make your journey from the airport to your destination hassle-free and comfortable. Choose us for your Mauritius airport transfer – the perfect start to your unforgettable adventure in Mauritius.&nbsp;Don’t believe us? Just check out our <a href="/en/testimonials">testimonials</a>.</p>
                            <h2 className="text-secondary">Explore Mauritius with our Premium Car Rental Services</h2>
                            <p>If you’re not just looking for an airport transfer, and would like to further explore the wonders of Mauritius, why not consider our rental car services.By hiring a car, you get the freedom to plan your journey and explore hidden gems of this paradise. And what better partner for this adventure than OLA Mauritius, the leading <a href="/en/car-rental">car rental service</a>?</p>
                            <p>OLA Mauritius offers a wide range of vehicles to match every need and budget. Be it a compact sedan for your business trip or a spacious SUV for a family holiday, we’ve got you covered. Our intuitive online booking platform makes securing your rental easy and convenient, plus you get amazing discounts for online bookings!</p>
                            <p>Enjoy a seamless experience with our constant customer service ready to assist you. Your journey is made even easier with our comprehensive maps, tourist guides, and GPS-equipped vehicles to help you navigate the island.</p>
                            <p>Choosing OLA Mauritius means you’re choosing comfort, freedom, and exceptional value. Begin your unforgettable Mauritian adventure today – explore the bustling city markets, bask on pristine beaches, and take the scenic routes along our breathtaking coastal roads. With OLA Mauritius, your island exploration is in your hands. Book today!</p>
                            <h2 className="text-secondary">Frequently Asked Questions (FAQs)</h2>
                            <h3 className="text-secondary">What happens once I’ve booked my airport transfer?</h3>
                            <p>We’ll pick you up at the airport when you land and drop you at the address of your choice. We also offer transfer to the airport when your holiday is over and can pick you up from your hotel or any custom location to the airport.</p>
                            <p>With OLA Mauritius, there is no need to be worried about delayed flights, as we’ll just wait for you.</p>
                            <p>OLA Mauritius is a private taxi service that you can trust! Unlike traditional taxi transfer services, we’re committed to being exactly on time with a simple price structure that you can trust.</p>
                            <h2 className="text-secondary">Do your Mauritius Airport transfers go to hotels?</h2>
                            <p>Yes, at OLA Mauritius our airport transfers cover hotels, and anywhere else you decide to stay.</p>
                            <p>Our incredibly knowledgeable drivers mean you can be certain of a smooth transfer to your destination if you’ve had a long flight.</p>
                            <h2 className="text-secondary">Do you offer shared airport transfers?</h2>
                            <p>No, if you book with us you can expect an entirely private airport transfer for you and your family, meaning you will have all the space you need to relax and recover from a long flight.</p>
                            <h2 className="text-secondary">Can I book a bespoke Mauritius airport transfer service?</h2>
                            <p>Of course! We’re incredibly flexible at OLA Mauritius and can pick you up at any time, from any airport to any location you choose.</p>
                            <p>By picking OLA Mauritius you get more control over your holiday than if you partnered with a taxi service. Our fleet of vehicles are very comfortable and always reliable, so you can sit back, relax and enjoy your holiday.</p>
                            <p>Partner with OLA Mauritius for private airport transfers you can trust!</p>
                        </div>
                }

                <div className="col-lg-5">
                    <div className="bg-secondary p-4">
                        <h4 className="orange semi-bold">
                            {
                                params.lang == "fr" ?
                                    "Demander un Devis de Transfert"
                                    :
                                    "Request Airport Transfer Quote"
                            }
                        </h4>
                        <div className="gform">
                            <div className="gf_browser_chrome gform_wrapper" id="gform_wrapper_2">
                                <form id="airportTransferForm" onSubmit={() => { }} className="w-100">
                                    <div className="row results-container">
                                        <h3 className="text-secondary my-3">
                                            {
                                                params.lang == "fr" ?
                                                    "DÉTAILS DU TRANSFERT"
                                                    :
                                                    "Transfer Details"
                                            }
                                        </h3>
                                        <div className="col-12 form-group">
                                            {
                                                params.lang == "fr" ?
                                                    <label className="mb-2 semi-bold" htmlFor="transferType">TYPE DE TRANSFERT*</label>
                                                    :
                                                    <label className="mb-2 semi-bold" htmlFor="transferType">Transfer type*</label>
                                            }
                                            <select
                                                id="transferType"
                                                name="transferType"
                                                className="form-select custom-select font-weight-bold input__checkout w-100"
                                                value={transferType} onChange={(e) => setTransferType(e.target.value)}
                                                required
                                            >
                                                <option value="1-way transfer">
                                                    {
                                                        params.lang == "fr" ?
                                                            "Transfert Simple"
                                                            :
                                                            "1-way transfer"
                                                    }
                                                </option>
                                                <option value="2-ways transfer">
                                                    {
                                                        params.lang == "fr" ?
                                                            "Transfert Double"
                                                            :
                                                            "2-ways transfer"
                                                    }
                                                </option>
                                            </select>
                                        </div>
                                        <div className="col-12 form-group">
                                            {
                                                params.lang == "fr" ?
                                                    <label className="mt-3 mb-2 semi-bold" htmlFor="noOfTravellers">NOMBRE D'OCCUPANTS*</label>
                                                    :
                                                    <label className="mt-3 mb-2 semi-bold" htmlFor="noOfTravellers">No of travellers*</label>
                                            }
                                            <select
                                                id="noOfTravellers"
                                                name="noOfTravellers"
                                                className="form-select custom-select font-weight-bold input__checkout w-100"
                                                value={noOfTravellers} onChange={(e) => setNoOfTravellers(e.target.value)}
                                                required
                                            >
                                                <option value="" defaultValue="selected"></option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="13">13</option>
                                                <option value="14">14</option>
                                                <option value="15">15</option>
                                                <option value="16">16</option>
                                                <option value="17">17</option>
                                                <option value="18">18</option>
                                                <option value="19">19</option>
                                                <option value="20">20</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-12 form-group">
                                            {
                                                params.lang == "fr" ?
                                                    <label className="mt-3 mb-2 semi-bold" htmlFor="pickupDate">DATE ET HEURE DE LA PRISE*</label>
                                                    :
                                                    <label className="mt-3 mb-2 semi-bold" htmlFor="pickupDate">Pickup Date / Time*</label>
                                            }
                                            <input
                                                id="pickupDate"
                                                name="pickupDate"
                                                className="form-control input-field"
                                                type="datetime-local"
                                                value={pickupDate}
                                                onChange={(e) => setPickupDate(e.target.value)}
                                                // value="2017-06-01T08:30"
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-12 form-group">
                                            {
                                                params.lang == "fr" ?
                                                    <label className="mt-3 mb-2 semi-bold" htmlFor="dropoffLocation">DESTINATION*</label>
                                                    :
                                                    <label className="mt-3 mb-2 semi-bold" htmlFor="dropoffLocation">Dropoff Location*</label>
                                            }
                                            <input
                                                id="dropoffLocation"
                                                name="dropoffLocation"
                                                className="form-control input-field"
                                                type="text"
                                                value={dropoffLocation} onChange={(e) => setDropoffLocation(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <h4 className="text-secondary mt-4 mb-3">
                                            {
                                                params.lang == "fr" ?
                                                    "CONTACT"
                                                    :
                                                    "Contact Details"
                                            }
                                        </h4>
                                        <div className="col-lg-12 form-group">
                                            {
                                                params.lang == "fr" ?
                                                    <label className="mb-2 semi-bold" htmlFor="fullName">NOMS*</label>
                                                    :
                                                    <label className="mb-2 semi-bold" htmlFor="fullName">Full Name*</label>
                                            }
                                            <input
                                                id="fullName"
                                                name="fullName"
                                                className="form-control input-field"
                                                type="text"
                                                value={fullName} onChange={(e) => setFullName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-12 form-group">
                                            {
                                                params.lang == "fr" ?
                                                    <label className="mt-3 mb-2 semi-bold" htmlFor="phoneNumber">NUMÉRO DE TÉLÉPHONE*</label>
                                                    :
                                                    <label className="mt-3 mb-2 semi-bold" htmlFor="phoneNumber">Phone Number*</label>
                                            }
                                            <input
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                className="form-control input-field"
                                                type="tel"
                                                value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-12 form-group">
                                            {
                                                params.lang == "fr" ?
                                                    <label className="mt-3 mb-2 semi-bold" htmlFor="email">ADDRESSE EMAIL*</label>
                                                    :
                                                    <label className="mt-3 mb-2 semi-bold" htmlFor="email">Email*</label>
                                            }
                                            <input
                                                id="email"
                                                name="email"
                                                className="form-control input-field"
                                                type="email"
                                                value={email} onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row justify-content-end">
                                        <div className="col-12 col-lg-6">
                                            <div className="mt-4">
                                                {
                                                    params.lang == "fr" ?
                                                        <input value="Demande de Devis" type="submit" className="btn btn-primary fw-bold w-100" />
                                                        :
                                                        <input value="Request Quote" type="submit" className="btn btn-primary fw-bold w-100" />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    </Fragment>);
}

export default AirportTransfer;