import { axiosPrivate } from "../services/axios";
import { useEffect } from "react";

const useAxiosPrivate = () => {

    useEffect(() => {

        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                config.headers['Accept'] = 'application/json';
                config.headers['Content-Type'] = config.url.includes('driver-profile') ? 'multipart/form-data' : 'application/json';
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 403 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    prevRequest.headers['Accept'] = 'application/json';
                    prevRequest.headers['Content-Type'] = 'application/json';
                    return axiosPrivate(prevRequest);
                }
                // return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [])

    return axiosPrivate;
}

export default useAxiosPrivate;