import React, { useState, useEffect } from 'react';
import {
    useLocation,
    useSearchParams
} from "react-router-dom";
import { useOutletContext } from 'react-router';
import { Helmet } from "react-helmet";

import { getCar } from '../../services/apiCarsService';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ProductCards from './productCards';
import MetaTags from '../../utils/MetaTags';

function Step3() {
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [car, setCar] = useState(false);
    const contextValue = useOutletContext()
    const [products, setProducts] = contextValue.products
    const [fetchedData, setFetchedData] = contextValue.fetchedData

    useEffect(() => {
            getResult();
            window.scrollTo({top: 0, left:0, behavior: 'instant'});
    }, []);

    let getResult = async () => {
        let carId = searchParams.get("id");
        let result = await getCar(axiosPrivate, carId, location.search);
        const carPrice = result.data?.vehicle.prices.total
        let duration = result.data?.duration.days;
        setProducts({price: carPrice})
        setFetchedData(result)
        result = result.data?.vehicle;
        if (!result || !duration) {
            return
        }
        setCar(result);
    }


    return (<>
        <MetaTags title='Step 3: Book your Vehicle Rental - OLA Mauritius' url={`step-3/${location.search}`} />

        <div className='bg-secondary'>
            {car ?
                <div className='results-container container'>

                    <div className="cars-header gap-4">

                        <div className="row">
                            <div className="col-12 mb-3 p-0">
                                <div className="card rounded-0 rounded-bottom">
                                    <div className="card-body">

                                        <div className="cars-header-title pb-4 semi-bold">
                                            1. SEARCH RESULTS
                                        </div>
                                        <div className="">
                                            {/* To re-do this part that tracks the progress */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h2 className="py-2 mt-3 bold">
                        Additional Options
                    </h2>
                    <h4 className="text-green py-2 semi-bold">
                        Equipments
                    </h4>
                    <hr></hr>

                    <section className="cars-section pt-4 pb-5">
                        <div className="container">
                            {!car.options.length ?
                                <div className="row gap-4">
                                    No options
                                </div>
                                :
                                < div className="row gap-4 accordion accordion__product-details justify-content-end">
                                    {car.options.map(option => 
                                        <ProductCards 
                                            key={option.id}
                                            option={option}
                                            duration={fetchedData.data?.duration.days} 
                                            setProducts={setProducts}
                                        />)}
                                </div>
                            }
                        </div>
                    </section>

                </div>
                :
                <div className="m-5 p-5">
                    <h3>Loading Booking form...</h3>
                </div>
            }
        </div>
    </>);
}

export default Step3;