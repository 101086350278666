// const API_URL = process.env.REACT_APP_API;
import blogs from "./../api/data/json/blogs.json";

async function getAllBlogs(axiosPrivate) {
    // let results = axiosPrivate.post(
    //     `${API_URL}/blogs`,
    //     {}
    // );

    // return results;

    return {
        data: [
            {
                imgUrl: "../../../images/car-parking.webp",
                title: "Seamless Car Rentals for Your Mauritius Journey",
                text: "At Ola Mauritius, we are committed to elevating your Mauritius car rental experience. Our focus on meticulous planning and organisation ensures that your journey, whether it's for leisure or business, is seamless and enjoyable.",
                url: "blog/?id=how-to-rent-a-car-in-mauritius",
            },
            {
                imgUrl: "../../../images/car-lock.webp",
                title: "Your One-Stop Car Hire Solution in Mauritius",
                text: "Explore Mauritius in comfort with OLA, your premier choice for car hire in Mauritius. Our attractive rental car packages are designed to offer both quality and affordability.",
                url: "blog/?id=road-tax-in-mauritius-everything-you-need-to-know",
            },
            {
                imgUrl: "../../../images/car-trip.webp",
                title: "Key Tip for a Smooth Car Rental in Mauritius",
                text: "When renting a car in Mauritius, consider the following tips for a hassle-free experience. Firstly, be mindful of toll roads; while their usage is not widespread, they can be a timesaver during heavy congestion.",
                url: "blog/?id=5-things-you-need-to-know-before-driving-in-mauritius",
            }
        ]
    }
}


async function getBlogs(axiosPrivate) {
    // let results = axiosPrivate.post(
    //     `${API_URL}/blogs`,
    //     {}
    // );

    // return results;

    return blogs;
}

// blog id=url
async function getBlog(axiosPrivate, url, lang) {
    // let results = axiosPrivate.post(
    //     `${API_URL}/blogs`,
    //     {}
    // );

    // return results;

    let blogsTemp = blogs.data;
    // use "en" records when there are no records for this lang param
    if (lang && blogsTemp[lang]?.length > 0) {
        blogsTemp = blogsTemp[lang];
    } else {
        blogsTemp = blogsTemp["en"];
    }
    let res = blogsTemp.find(blog => blog.url == url);
    return res;
}

export {
    getAllBlogs,
    getBlogs,
    getBlog
}