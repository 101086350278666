import { Fragment } from "react";
import { Helmet } from "react-helmet"
import { useParams } from "react-router-dom";

const MetaTags = ({ title, url = '', type = 'article', description, ogDescription, ogImage = '/logo512.png', lang = 'en', altLang = 'fr' }) => {
    const params = useParams()
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="keywords" content="Rent a car, Car Rental, Hire Car" />
            <meta property="og:title" content={title} />
            <meta property="og:url" content={`https://www.olamauritius.com/${params.lang}/${url}`} />
            <meta property="og:type" content={type} />
            <link rel="canonical" href={`https://www.olamauritius.com/${lang}/${url}`} />
            <link rel="alternate" href={`https://www.olamauritius.com/${altLang}/${url}`} hreflang={altLang} />
            <link rel="alternate" href={`https://www.olamauritius.com/${lang}/${url}`} hreflang={lang} />
            <meta property="og:image" content={ogImage} />
            <meta property="og:image:secure_url" content={ogImage} />
            {description && <meta name="description" content={description} />}
            {ogDescription && <meta property="og:description" content={ogDescription} />}
        </Helmet>
    )
}

export default MetaTags