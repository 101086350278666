const API_URL = process.env.REACT_APP_API;
const APP_INSTANCE = process.env.REACT_APP_INSTANCE;


async function postBooking(axiosPrivate, body) {
    let result = await axiosPrivate.post(
        `${API_URL}/api/book?instance=${APP_INSTANCE}`,
        // `http://localhost:3009/api/book`,
        body,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );

    return result;
}

async function postDriverData(axiosPrivate, body, bookingId) {
    let result = await axiosPrivate.post(
        `${API_URL}/api/bookings/${bookingId}/driver-profile`,
        body,
    );

    return result;
}

async function getCar(axiosPrivate, carId, search) {
    let result = await axiosPrivate.get(
        `${API_URL}/api/book/vehicles/${carId}${search}&instance=${APP_INSTANCE}`,
        // `http://localhost:3009/api/book/vehicles/${carId}${search}`,
        {}
    );

    return result;
}

async function getAllCars(axiosPrivate, search) {

    let axiosResults = await axiosPrivate.get(
        `${API_URL}/api/book/vehicles${search}&instance=${APP_INSTANCE}`,
        // `http://localhost:3009/api/book/vehicles${search}`,
        {}
    );
    // if (axiosResults.status == "200") {
    //     results = axiosResults;
    // }

    return axiosResults;
}

async function getVoucher(axiosPrivate, id) {

    let axiosResults = await axiosPrivate.get(
        `${API_URL}/api/bookings/${id}/voucher?instance=${APP_INSTANCE}`,
        {}
    );
    return axiosResults;
}

async function sendContact(axiosPrivate, data) {

    let axiosResults = await axiosPrivate.post(
        `${API_URL}/api/contact-us?instance=${APP_INSTANCE}`,
        data
    );
    return axiosResults;
}

export {
    postBooking,
    getCar,
    getAllCars,
    postDriverData,
    getVoucher,
    sendContact
}