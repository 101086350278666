import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useSearchParams, useNavigate, useParams } from "react-router-dom";

import lang from "../lang/lang.json";
import { Helmet } from 'react-helmet';

function Benefits() {
	const params = useParams();

	return (<Fragment>
	  <Helmet>
            <title>Benefits - OLA Mauritius</title>
            <meta name="keywords" content="Rent a car, Car Rental, Hire Car" />
            <meta property="og:title" content="Benefits - OLA Mauritius" />
            <meta property="og:url" content="https://www.olamauritius.com/en/benefits/" />
            <meta property="og:type" content="article" />
            <link rel="canonical" href="https://www.olamauritius.com/en/benefits/" />
			<link rel="alternate" href="https://www.olamauritius.com/en/benefits/" hreflang="en" />
			<link rel="alternate" href="https://www.olamauritius.com/fr/benefits/" hreflang="fr" />
			<meta name="description" content="Choosing OLA Mauritius as your car rental partner means you will benefit from our exclusive benefits and thus enhancing your discovery of Mauritius through your unique car hiring experience." />
			<meta property="og:description" content="Choosing OLA Mauritius as your car rental partner means you will benefit from our exclusive benefits and thus enhancing your discovery of Mauritius through your unique car hiring experience." />
			<meta property="og:image" content="/images/pages/benefit-fleet.webp" />
			<meta property="og:image:secure_url" content="/images/pages/benefit-fleet.webp" />
			<meta property="og:image" content="/images/pages/benefit-gprs.webp" />
			<meta property="og:image:secure_url" content="/images/pages/benefit-gprs.webp" />
			<meta property="og:image" content="/images/pages/benefit-additional-driver.webp" />
			<meta property="og:image:secure_url" content="/images/pages/benefit-additional-driver.webp" />
			<meta property="og:image" content="/images/pages/benefit-car-seat.webp" />
			<meta property="og:image:secure_url" content="/images/pages/benefit-car-seat.webp" />
			<meta property="og:image" content="/images/pages/benefit-insurance.webp" />
			<meta property="og:image:secure_url" content="/images/pages/benefit-insurance.webp" />
			<meta property="og:image" content="/images/pages/benefit-assistance.webp.webp" />
			<meta property="og:image:secure_url" content="/images/pages/benefit-assistance.webp.webp" />
			<meta property="og:image" content="/images/pages/benefit-keys.webp" />
			<meta property="og:image:secure_url" content="/images/pages/benefit-keys.webp" />
    </Helmet>

	<div className="ola-old-pages">
		<section className="internal-header">

			<div className="container">
				<div className="row v-align-children">
					<div className="col-md-8 bold">
						<h1>
							{lang[params.lang]?.footer.benefits || "Benefits"}
						</h1>
					</div>

					<div className="col-md-4 d-none d-md-block">
						<ol className="breadcrumb float-end semi-bold">
							<li>
								<a href={params.lang ? `/${params.lang}/` : `en/`}>
									{
										params.lang == "fr" ?
											"Accueil"
											:
											"Home"
									}
								</a>
							</li>
							<li className="current">
								{lang[params.lang]?.footer.benefits || "Benefits"}
							</li>
						</ol>
					</div>

				</div>
			</div>
		</section>

		<div className="container mt-2 mb-5">
			{
				params.lang == "fr" ?
					<div className="row">
						<div className="col-md-8">
							<section className="text-box">
								<article>
									<p>Choisissez OLA Mauritius comme votre agence de location de voitures et bénéficiez de nos avantages exclusifs et profitez aussi pleinement de l’île Maurice à travers une expérience unique de location de voiture.  Voici les avantages dont vous bénéficierez en louant chez nous :</p>
									<div id="benefits">
										<p><a name="fleet"></a></p>
										<h3 className="text-secondary"><span className="accent">1.</span>Des voitures haut de gamme</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-fleet.webp" alt="fleet" width="136" height="58" />
											Nos voitures sont récentes et en très bon état. Nous adhérons aux normes internationales et de par notre engagement en termes de qualité vous êtes sûr de disposer d’excellents véhicules bien entretenus et propres.
											<br />
											<a name="gps"></a></p>
										<h3 className="text-secondary"><span className="accent">2.</span>MIFI</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-gprs.webp" alt="gps" width="136" height="58" />
											Expérimentez une connectivité sans faille avec notre WiFi mobile haut de gamme à l'île Maurice, offrant des données illimitées à des prix compétitifs. Restez connecté où que vous soyez avec un accès facile à Google Maps et partagez des moments instantanément avec vos proches. Notre MiFi portable et convivial améliore votre expérience de location de voiture.
											<a name="driver"></a></p>
										<h3 className="text-secondary"><span className="accent">3.</span>Conducteur supplémentaire</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-additional-driver.webp" alt="additional-driver" width="136" height="58" />
											Ajoutez un conducteur supplémentaire à votre location à des tarifs très compétitifs. Profitez de la commodité de partager la conduite tout en respectant votre budget. Simple et abordable!
											<a name="child"></a></p>
										<h3 className="text-secondary"><span className="accent">4.</span>Sièges auto pour enfants</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-car-seat.webp" alt="car-seat" width="136" height="58" />
											Votre sécurité, y compris celle de votre enfant, est quelque chose dont nous nous soucions énormément. Toutes nos voitures sont équipées avec un siège pour enfant a un prix compétitif .Il suffit de le préciser lors de la réservation en ligne.
											<a name="insurance"></a></p>
										<h3 className="text-secondary"><span className="accent">5.</span>Meilleure couverture d'assurance</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-insurance.webp" alt="insurance" width="136" height="58" />
											Ayez l’esprit tranquille grâce à notre forfait d’assurance complet. Choisissez entre la franchise sur votre carte de crédit ou de racheter la franchise à zéro, selon vos besoins. Restez couvert, restez confiant !
											<a name="road"></a></p>
										<h3 className="text-secondary"><span className="accent">6.</span> Une assistance routière de 24 heures</h3>
										<hr />
										<p><img className="float-end" src="../../images/pages/benefit-assistance.webp" alt="assistance" width="136" height="58" />
											Nous offrons, en même temps que la voiture, une assistance routière 24/7 en cas de panne mécanique. Notre équipe vous aidera à reprendre la route même si cela nécessite le remplacement de la voiture par une autre.
											<a name="delivery"></a></p>
										<h3 className="text-secondary"><span className="accent">7.</span>Livraison et récupération du véhicule gratuite</h3>
										<hr />
										<p><img className="float-end" src="../../images/pages/benefit-keys.webp" alt="keys" width="136" height="58" />
											Nous offrons la possibilité d’obtenir  et de rendre votre voiture  à  l’aéroport, votre hôtel ou villa ou autres emplacements,selon votre convenance.  Les déplacements à l’aéroport sont toujours gratuits,ceux à l’hôtel sont gratuits pour les locations de plus de 5 jours.</p>
									</div>
								</article>



							</section>
						</div>

						<div className="sidebar col-md-4">
							<aside className="sidebar-widget">
								<h4 className="widget-title semi-bold">Vos Plus</h4>
								<hr />
								<div className="textwidget semi-bold">
									<div><i className="bi bi-check"></i>Flotte diversifiée</div>
									<div><i className="bi bi-check"></i>Prix compétitifs</div>
									<div><i className="bi bi-check"></i>Meilleures couvertures d'assurance</div>
									<div><i className="bi bi-check"></i>Annulation gratuite</div>
									<div><i className="bi bi-check"></i>Service client 24h/24 et 7j/7</div>
									<div><i className="bi bi-check"></i>Aucun frais caché</div>
								</div>
							</aside>
							<aside className="sidebar-widget">
								<h4 className="widget-title mt-4">Prix ​​bas garanti</h4>
								<hr />
								<div className="textwidget">
									<div className="pricing-option active ">
										<strong>Le prix commence à</strong>
										<div className="price-container text-center">
											<span className="dollar text-secondary">€</span>
											<span className="price text-secondary">25</span>
											<span className="terms text-secondary">/jour</span>
										</div>
										<img className="d-block m-auto" src="../../images/pages/homecar.webp" alt="car" width="213" height="104" />

										<a href="/fr" className="btn btn-primary fw-bold w-100 text-white my-4">
											Rechercher une voiture
										</a>

									</div></div>
							</aside>

						</div>

					</div>
					:
					<div className="row">
						<div className="col-md-8">
							<section className="text-box">
								<article>
									<p>Choosing OLA Mauritius as your car rental partner means you will <strong>benefit from our exclusive benefits</strong> and thus enhancing your discovery of Mauritius through your <strong>unique car hiring experience</strong>. Here is what you get by renting with us:</p>
									<div id="benefits">
										<p><a name="fleet"></a></p>
										<h3 className="text-secondary"><span className="accent">1.</span>A-Class Fleet</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-fleet.webp" alt="fleet" width="136" height="58" />
											Our cars are spotlessly clean and in excellent roadworthy condition. We stick to international standards and will never compromise with quality. We are simply obsessed to excellence!
											<br />
											<a name="gps"></a></p>
										<h3 className="text-secondary"><span className="accent">2.</span>MIFI</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-gprs.webp" alt="gps" width="136" height="58" />
											Experience seamless connectivity with our top-rated mobile WiFi in Mauritius, offering unlimited data at competitive prices. Stay connected on the go with easy access to Google Maps and share moments instantly with loved ones. Our portable, user-friendly MiFi enhances your car rental experience.<br />
											<a name="driver"></a></p>
										<h3 className="text-secondary"><span className="accent">3.</span>Additional Driver</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-additional-driver.webp" alt="additional-driver" width="136" height="58" />
											Add an extra driver to your rental at highly competitive rates. Enjoy the convenience of sharing the drive while staying within your budget. Simple, affordable, and hassle-free!<br />
											<a name="child"></a></p>
										<h3 className="text-secondary"><span className="accent">4.</span>&nbsp;Child Seats</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-car-seat.webp" alt="car-seat" width="136" height="58" />
											Your safety including that of your child is something we care a lot about. All our cars comes with child seat at competitive prices – simply specify it during your booking process with us.<br />
											<a name="insurance"></a>
										</p>
										<h3 className="text-secondary"><span className="accent">5.</span>⁠Best Insurance Cover</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-insurance.webp" alt="insurance" width="136" height="58" />
											Get complete peace of mind with our all-inclusive insurance package. Choose between excess on hold or the full protection fee, tailored to fit your needs. Stay covered, stay confident!<br />
											<a name="road"></a>
										</p>
										<h3 className="text-secondary"><span className="accent">6.</span> 24 hours Roadside Assistance</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-assistance.webp" alt="assistance" width="136" height="58" />
											Our cars come with our roadside assistance available 24/7 in case of mechanical breakdown. Our team will get you back on the road if it means replacing your car with another one.<br />
											<a name="delivery"></a>
										</p>
										<h3 className="text-secondary"><span className="accent">7.</span>Free Delivery &amp; Pick up</h3>
										<hr />
										<p>
											<img className="float-end" src="../../images/pages/benefit-keys.webp" alt="keys" width="136" height="58" />
											We offer free delivery and pickup to/from the Airport, your hotel or villa or any other custom locations. Airport deliveries are always free – hotel deliveries are free for more than 6+ days rental. Charges will vary depending seasons and locations.
										</p>
									</div>
								</article>



							</section>
						</div>

						<div className="sidebar col-md-4">
							<aside className="sidebar-widget">
								<h4 className="widget-title semi-bold">Benefits</h4>
								<hr />
								<div className="textwidget semi-bold">
									<div><i className="bi bi-check"></i>Diverse Fleet</div>
									<div><i className="bi bi-check"></i>Competitive prices</div>
									<div><i className="bi bi-check"></i>Best Insurance Covers</div>
									<div><i className="bi bi-check"></i>Free cancellation</div>
									<div><i className="bi bi-check"></i>24/7 customer care</div>
									<div><i className="bi bi-check"></i>No hidden charges</div>
								</div>
							</aside>
							<aside className="sidebar-widget">
								<h4 className="widget-title mt-4 bold">Guaranteed Low Price</h4>
								<hr />
								<div className="textwidget">
									<div className="pricing-option active bold">
										<strong>Price starts at</strong>
										<div className="price-container text-center">
											<span className="dollar text-secondary">€</span>
											<span className="price text-secondary">25</span>
											<span className="terms text-secondary">/day</span>
										</div>
										<img className="d-block m-auto" src="../../images/pages/homecar.webp" alt="car" width="213" height="104" />

										<a href="/en" className="btn btn-primary w-100 text-white my-4">Search for a Car</a>

									</div></div>
							</aside>

						</div>

					</div>
			}

		</div>
	</div>
	</Fragment>);
}

export default Benefits;