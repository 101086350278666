import React, { useState, useEffect, Fragment } from 'react';
import { useSearchParams, Link, useParams } from "react-router-dom";
import DOMPurify from 'dompurify'

import { formatDate } from '../../utils/dateUtils';
import Posts from '../../components/Posts';
import { getBlog } from '../../services/apiBlogsService';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import MetaTags from '../../utils/MetaTags';
import blogs from "./../../api/data/json/blogs.json";
import { Helmet } from 'react-helmet';
import SearchForm from '../../components/SearchForm';


function BlogDetails() {
    const params = useParams();
    const axiosPrivate = useAxiosPrivate();
    const [showBlogPlaceholder, setshowBlogPlaceholder] = useState(true);
    const [blog, setBlog] = useState(false);
    const [blogData, setBlogData] = useState({
        title: '',
        description: '',
        url: '',
        type: ''
    })
    const [placeholder, setPlaceholder] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const routeId = searchParams.get("id");

    useEffect(() => {
        console.log("searchParams: ", searchParams)
        getPlaceholders();
            getBlogDetails();
            window.scrollTo({top: 0, left:0, behavior: 'instant'});
    }, [searchParams]);

    let getBlogDetails = async () => {
        setshowBlogPlaceholder(true);
        let result = await getBlog(axiosPrivate, routeId, params.lang);
        console.log("blog: ", result)
        // result = result?.data

        let blogSections;

        if (result.inner_html?.length > 0) {
            blogSections = <div dangerouslySetInnerHTML={
                { __html: DOMPurify.sanitize(result.inner_html) }
            } />
        } else {
            blogSections = []
            if (result && result.sections.length > 0) {
                for (let i = 0; i < result.sections.length; i++) {
                    let blogSectionParagraphs = [];
                    for (let p = 0; p < result.sections[i].text.length; p++) {
                        blogSectionParagraphs.push(
                            <p>
                                {result.sections[i].text[p]}
                            </p>
                        );
                    }
                    console.log("{result.sections[i]", result.sections[i])
                    blogSections.push(
                        <div>
                            <h2 className="blog-section-titlе semi-bold">
                                {result.sections[i].title}
                            </h2>
                            <div className="blog-section-text">
                                {blogSectionParagraphs}
                            </div>
                        </div>
                    );
                }
            }
        }

        let blogTemp =
            <div className="blog">
                <div className="hero">
                    <div className="container hero-text-container p-3 p-md-5">
                        <div className="blog-time semi-bold">
                            {result.timestamp && formatDate(new Date(result.timestamp), "DD MMM, YYYY")}
                        </div>
                        <h1 className="blog-title semi-bold">{result.title}</h1>
                    </div>

                    <div className="hero-image" aria-hidden="true">
                        <img src={result.imgUrl} alt={result.imgAlt || result.title} aria-hidden="true" />
                    </div>
                </div>


                {/* <div className="img-wrp">
                    <img className="blog-img" src={result.imgUrl} alt="Car parking" />
                    <div className="blog-time">{result.timestamp}</div>
                    <h1 className="blog-title">{result.title}</h1>
                    <div className="blog-author">{result.title}</div>
                </div> */}
                <div className="blog-body mx-auto px-lg-5 px-4">
                    {blogSections}
                </div>
            </div>

        setBlog(blogTemp);

        setBlogData({
            title: result.title,
            description: result.text,
            url: result.url,
            type: result.type
        })
        setshowBlogPlaceholder(false);
    }

    let getPlaceholders = async () => {
        let placeholderTemp = [];
        for (let i = 0; i < 3; i++) {
            placeholderTemp.push(
                <div className="blog blog--plcaseholder" key={i}>
                    <div className="card-img-top">
                        <div className="load-wraper">
                            <div className="activity"></div>
                        </div>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">
                            <div className="load-wraper">
                                <div className="activity"></div>
                            </div></h5>
                        <div className="card-text">
                            <div className="load-wraper">
                                <div className="activity"></div>
                            </div></div>
                        <div className="btn"></div>
                    </div>
                </div>
            );
        }

        if (placeholderTemp.length > 0) {
            setPlaceholder(placeholderTemp);
        }
    }

    const imgUrl = `/images/pages/${blogData.url}.webp`
    const frAltBlog = blogs.data.fr.find(blog => blog?.altTitle?.includes(blogData.title))
    const enAltBlog = blogs.data.en.find(blog => blog?.altTitle?.includes(blogData.title))

    return (<Fragment>

        <Helmet>
            <title>{blogData.title}</title>
            <meta name="keywords" content="Rent a car, Car Rental, Hire Car" />
            <meta property="og:title" content={blogData.title} />
            <meta property="og:url" content={`https://www.olamauritius.com/?id=${params.lang}/${blogData?.url}/`} />
            <meta property="og:type" content='article' />
            <link rel="canonical" href={`https://www.olamauritius.com/en/?id=${enAltBlog?.url || blogData.url}/`} />
            {frAltBlog?.url && <link rel="alternate" href={`https://www.olamauritius.com/fr/?id=${frAltBlog?.url}/`} hreflang='fr' />}
            {enAltBlog?.url && <link rel="alternate" href={`https://www.olamauritius.com/fr/?id=${blogData?.url}/`} hreflang='fr' />}
            <link rel="alternate" href={`https://www.olamauritius.com/en/?id=${enAltBlog?.url || blogData.url}/`} hreflang='en' />
            <meta property="og:image" content={imgUrl} />
            <meta property="og:image:secure_url" content={imgUrl} />
            <meta name="description" content={blogData.description} />
            <meta property="og:description" content={blogData.description} />
            <meta property="og:image:width" content="1600" />
            <meta property="og:image:height" content="575" />
            <meta property="article:section" content="Uncategorized" />
            <meta property="article:published_time" content="2024-04-09T00:00:00+00:00" />
        </Helmet>

        <section className="blog-section">
            <div className="pb-5">
                {!!showBlogPlaceholder ?
                    <>
                        {placeholder}
                    </>
                    :
                    <>
                        {blog}
                    </>
                }
            </div>

            {
                blogData.type === 'location'
                ?
                <div className="blog-body mx-auto px-lg-5 px-4 mb-5">
                    <SearchForm />
                </div>
                :
                <Posts />
            }

        </section>
    </Fragment>);
}

export default BlogDetails;