// const API_URL = process.env.REACT_APP_API;

async function getAllLocations(axiosPrivate) {
    // let results = axiosPrivate.post(
    //     `${API_URL}/locations`,
    //     {}
    // );

    // return results;

    return {
        data: [
            {
                title: "SSR International Airport",
                text: 'To rent a car, drivers must be at least 22 years old. Those under 26 will incur a "young driver" fee. On the other end of the spectrum, while we accommodate drivers up to 80 years old, those aged 70 and above will be subject to a "senior driver" fee.',
                url: "blog/?id=ssr-international-airport"
            },
            {
                title: "Grand Baie",
                text: 'To rent a car, drivers must be at least 22 years old. Those under 26 will incur a "young driver" fee. On the other end of the spectrum, while we accommodate drivers up to 80 years old, those aged 70 and above will be subject to a "senior driver" fee.',
                url: "blog/?id=grand-baie"
            },
            {
                title: "Flic en Flac",
                text: 'To rent a car, drivers must be at least 22 years old. Those under 26 will incur a "young driver" fee. On the other end of the spectrum, while we accommodate drivers up to 80 years old, those aged 70 and above will be subject to a "senior driver" fee.',
                url: "blog/?id=flic-en-flac"
            },
            {
                title: "Belle Mare",
                text: 'To rent a car, drivers must be at least 22 years old. Those under 26 will incur a "young driver" fee. On the other end of the spectrum, while we accommodate drivers up to 80 years old, those aged 70 and above will be subject to a "senior driver" fee.',
                url: "blog/?id=belle-mare"
            },
            {
                title: "Pereybere",
                text: 'To rent a car, drivers must be at least 22 years old. Those under 26 will incur a "young driver" fee. On the other end of the spectrum, while we accommodate drivers up to 80 years old, those aged 70 and above will be subject to a "senior driver" fee.',
                url: "blog/?id=pereybere"
            },
            {
                title: "Blue Bay",
                text: 'To rent a car, drivers must be at least 22 years old. Those under 26 will incur a "young driver" fee. On the other end of the spectrum, while we accommodate drivers up to 80 years old, those aged 70 and above will be subject to a "senior driver" fee.',
                url: "blog/?id=blue-bay"
            },
            {
                title: "Le Morne",
                text: 'To rent a car, drivers must be at least 22 years old. Those under 26 will incur a "young driver" fee. On the other end of the spectrum, while we accommodate drivers up to 80 years old, those aged 70 and above will be subject to a "senior driver" fee.',
                url: "blog/?id=le-morne"
            },
            {
                title: "Tamarin",
                text: 'To rent a car, drivers must be at least 22 years old. Those under 26 will incur a "young driver" fee. On the other end of the spectrum, while we accommodate drivers up to 80 years old, those aged 70 and above will be subject to a "senior driver" fee.',
                url: "blog/?id=tamarin"
            },
            {
                title: "Rivière Noire",
                text: 'To rent a car, drivers must be at least 22 years old. Those under 26 will incur a "young driver" fee. On the other end of the spectrum, while we accommodate drivers up to 80 years old, those aged 70 and above will be subject to a "senior driver" fee.',
                url: "blog/?id=riviere-noire"
            },
            {
                title: "Calodyne",
                text: 'To rent a car, drivers must be at least 22 years old. Those under 26 will incur a "young driver" fee. On the other end of the spectrum, while we accommodate drivers up to 80 years old, those aged 70 and above will be subject to a "senior driver" fee.',
                url: "blog/?id=calodyne"
            },
            {
                title: "Grand Gaube",
                text: 'To rent a car, drivers must be at least 22 years old. Those under 26 will incur a "young driver" fee. On the other end of the spectrum, while we accommodate drivers up to 80 years old, those aged 70 and above will be subject to a "senior driver" fee.',
                url: "blog/?id=grand-gaube"
            },
            {
                title: "Anse La Raie",
                text: 'To rent a car, drivers must be at least 22 years old. Those under 26 will incur a "young driver" fee. On the other end of the spectrum, while we accommodate drivers up to 80 years old, those aged 70 and above will be subject to a "senior driver" fee.',
                url: "blog/?id=anse-la-raie"
            },
            {
                title: "Mahebourg",
                text: 'To rent a car, drivers must be at least 22 years old. Those under 26 will incur a "young driver" fee. On the other end of the spectrum, while we accommodate drivers up to 80 years old, those aged 70 and above will be subject to a "senior driver" fee.',
                url: "blog/?id=mahebourg"
            },
            {
                title: "Bel Ombre",
                text: 'To rent a car, drivers must be at least 22 years old. Those under 26 will incur a "young driver" fee. On the other end of the spectrum, while we accommodate drivers up to 80 years old, those aged 70 and above will be subject to a "senior driver" fee.',
                url: "blog/?id=bel-ombre"
            }
        ]
    }
}

export {
    getAllLocations
}