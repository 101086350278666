import React from 'react';
import { useParams } from 'react-router';

import lang from "../lang/lang.json";
import SearchForm from "./SearchForm";

function Hero() {
    const params = useParams();

    return (
        <>
            <section className="hero">
                <div className="container-xxl hero-text-container card">
                    <h1 className="hero-title">
                        {lang[params.lang]?.searchForm.formTitle || "Search for car rental in Mauritius"}
                    </h1>
                    <SearchForm />
                </div>

                <div className="container p-3 text-white">
                    <div className="row text-center">
                        <div className="col-md-4 ms-auto p-3 hero-text justify-content-center">
                            <svg width="22" height="22" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0 10.5C0.00657546 4.70374 4.70374 0.00657546 10.5 0C16.2963 0.00657546 20.9934 4.70374 21 10.5C21 13.125 20.3232 15.3119 18.9883 17.0005C18.8978 17.1151 18.7597 17.182 18.6136 17.1818H2.38636C2.24029 17.182 2.10221 17.1151 2.0117 17.0005C0.676749 15.3119 0 13.125 0 10.5ZM0.954545 10.5C0.954545 12.81 1.51572 14.7353 2.62221 16.2273H18.3778C19.4843 14.7353 20.0455 12.81 20.0455 10.5C20.0455 5.22819 15.7718 0.954545 10.5 0.954545C5.22819 0.954545 0.954545 5.22819 0.954545 10.5Z" fill="#646464"/>
                                <path d="M10.5002 3.81815C10.6268 3.81822 10.7483 3.76797 10.8378 3.67844C10.9273 3.58892 10.9776 3.46748 10.9775 3.34088V2.38633C10.9775 2.12274 10.7638 1.90906 10.5002 1.90906C10.2366 1.90906 10.0229 2.12274 10.0229 2.38633V3.34088C10.0229 3.46748 10.0731 3.58892 10.1627 3.67844C10.2522 3.76797 10.3736 3.81822 10.5002 3.81815V3.81815Z" fill="#646464"/>
                                <path d="M5.49518 4.29688C5.37445 4.17632 5.19858 4.12932 5.03382 4.17359C4.86905 4.21787 4.74042 4.34668 4.69637 4.5115C4.65233 4.67633 4.69957 4.85214 4.82029 4.97269L5.49613 5.64758C5.68275 5.83395 5.98512 5.83374 6.17148 5.64712C6.35785 5.46049 6.35764 5.15813 6.17102 4.97176L5.49518 4.29688Z" fill="#646464"/>
                                <path d="M16.1799 4.29729C15.9935 4.1112 15.6917 4.111 15.505 4.29684L14.8292 4.97173C14.6438 5.15832 14.6442 5.45963 14.8301 5.64572C15.0159 5.83181 15.3172 5.83262 15.5041 5.64755L16.1799 4.97266C16.3663 4.78613 16.3663 4.48382 16.1799 4.29729Z" fill="#646464"/>
                                <path d="M16.9356 13.3516C16.749 13.1652 16.4466 13.1654 16.2602 13.352C16.0739 13.5387 16.0741 13.841 16.2607 14.0274L16.9366 14.7023C17.1234 14.8874 17.4247 14.8865 17.6105 14.7004C17.7964 14.5143 17.7968 14.213 17.6114 14.0264L16.9356 13.3516Z" fill="#646464"/>
                                <path d="M4.06409 13.3515L3.38825 14.0264C3.20292 14.213 3.20332 14.5143 3.38915 14.7004C3.57498 14.8865 3.87629 14.8873 4.06314 14.7023L4.73898 14.0274C4.92431 13.8408 4.92391 13.5395 4.73808 13.3534C4.55225 13.1673 4.25094 13.1664 4.06409 13.3515V13.3515Z" fill="#646464"/>
                                <path d="M4.29532 9.54551C4.2954 9.41891 4.24514 9.29747 4.15562 9.20794C4.06609 9.11842 3.94465 9.06816 3.81805 9.06824H2.8635C2.59991 9.06824 2.38623 9.28192 2.38623 9.54551C2.38623 9.8091 2.59991 10.0228 2.8635 10.0228H3.81805C3.94465 10.0229 4.06609 9.9726 4.15562 9.88308C4.24514 9.79355 4.2954 9.67211 4.29532 9.54551V9.54551Z" fill="#646464"/>
                                <path d="M18.1364 9.06824H17.1819C16.9183 9.06824 16.7046 9.28192 16.7046 9.54551C16.7046 9.8091 16.9183 10.0228 17.1819 10.0228H18.1364C18.4 10.0228 18.6137 9.8091 18.6137 9.54551C18.6137 9.28192 18.4 9.06824 18.1364 9.06824Z" fill="#646464"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M11.0812 8.72031L12.764 7.03704C12.9512 6.85548 13.2494 6.85775 13.4338 7.04213C13.6182 7.22652 13.6205 7.52476 13.4389 7.71193L11.7707 9.38052C12.0962 10.0149 11.916 10.7917 11.3443 11.2179C10.7726 11.6441 9.97666 11.5951 9.46161 11.1019C8.94656 10.6087 8.86302 9.81572 9.264 9.22606C9.66498 8.63641 10.4332 8.4226 11.0812 8.72031ZM10.0225 10.0261C10.0225 10.2896 10.2362 10.5033 10.4998 10.5033C10.7632 10.503 10.9767 10.2895 10.977 10.0261C10.977 9.76247 10.7634 9.54878 10.4998 9.54878C10.2362 9.54878 10.0225 9.76247 10.0225 10.0261Z" fill="#646464"/>
                            </svg>
                            Unlimited Mileage on all vehicles
                        </div>
                        <div className="col-md-4 ms-auto p-3 hero-text justify-content-center">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M16.9784 7.766C16.9101 7.76587 16.8425 7.75191 16.7797 7.72497C13.0414 6.10777 6.95839 6.10777 3.22012 7.72497C3.03478 7.8052 2.81938 7.76605 2.67412 7.62573C2.52886 7.48542 2.48228 7.2715 2.55604 7.0835C3.7538 4.01783 6.70855 2 9.99989 2C13.2912 2 16.246 4.01783 17.4437 7.0835C17.5041 7.23737 17.4846 7.41114 17.3914 7.54771C17.2983 7.68429 17.1437 7.766 16.9784 7.766ZM15.9749 6.35935C14.7126 4.26669 12.4438 2.99105 9.99989 2.99997C7.55603 2.99105 5.28716 4.26669 4.02489 6.35935C5.96182 5.77333 7.97633 5.48409 9.99989 5.50145C12.0235 5.48409 14.038 5.77333 15.9749 6.35935Z" fill="#646464"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 4.47715 4.47715 0 10 0C15.5203 0.00614486 19.9939 4.4797 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM1 10C1 14.9706 5.02944 19 10 19C14.9682 18.9942 18.9942 14.9682 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10Z" fill="#646464"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M17.4256 10.0523C17.5719 10.0397 17.7165 10.0912 17.8221 10.1934C17.927 10.2961 17.9813 10.4399 17.9705 10.5864C17.7031 14.1363 15.1281 17.0852 11.6467 17.8286C11.613 17.8358 11.5787 17.8394 11.5442 17.8394C11.4055 17.8394 11.273 17.7818 11.1784 17.6803C11.0839 17.5788 11.0358 17.4426 11.0457 17.3042C11.3573 12.8579 13.5037 10.418 17.4256 10.0523ZM12.1107 16.6743C12.5149 13.42 14.0887 11.5977 16.9012 11.1279C16.4707 13.7288 14.6212 15.8701 12.1107 16.6743Z" fill="#646464"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M2.17762 10.1933C2.28406 10.0926 2.42799 10.0414 2.57412 10.0522C6.49599 10.4179 8.64239 12.8579 8.95394 17.3042C8.96384 17.4425 8.91576 17.5788 8.8212 17.6803C8.72665 17.7818 8.59414 17.8394 8.45542 17.8393C8.42097 17.8394 8.38661 17.8358 8.35292 17.8286C4.87156 17.0851 2.29663 14.1362 2.02919 10.5864C2.01835 10.4399 2.07266 10.2961 2.17762 10.1933ZM3.09852 11.1279C5.91102 11.5976 7.48477 13.4199 7.88904 16.6743C5.37852 15.87 3.52902 13.7287 3.09852 11.1279Z" fill="#646464"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M10 8C11.1046 8 12 8.89543 12 10C11.9989 11.1041 11.1041 11.9989 10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8ZM9 10C9 10.5523 9.44772 11 10 11C10.552 10.9993 10.9993 10.552 11 10C11 9.44772 10.5523 9 10 9C9.44772 9 9 9.44772 9 10Z" fill="#646464"/>
                            </svg>
                            {lang[params.lang]?.pages.home.insuranceCover || "Comprehensive insurance cover"}
                        </div>
                        <div className="col-md-4 ms-auto p-3 hero-text justify-content-center">
                            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.6938 4.03881L10.1938 0.0388125C10.0698 -0.0129375 9.9302 -0.0129375 9.80615 0.0388125L0.30615 4.03881C0.12063 4.11689 -1.31063e-05 4.29856 0 4.49984V17.4998C0 17.776 0.223858 17.9998 0.5 17.9998C0.776142 17.9998 1 17.776 1 17.4998V4.83179L10 1.04234L19 4.83179V17.4998C19 17.776 19.2239 17.9998 19.5 17.9998C19.7761 17.9998 20 17.776 20 17.4998V4.49984C20 4.29856 19.8794 4.11689 19.6938 4.03881Z" fill="#646464"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.8894 11.0575L14.479 6.35623C14.4154 6.14478 14.2208 5.99998 14 5.99988H6C5.77918 6 5.58453 6.14484 5.521 6.35633L4.1106 11.0575C3.45721 11.2324 3.00209 11.8235 3 12.4999V14.4999C3.00192 15.1335 3.40239 15.6973 4 15.9078V16.4999C4 17.3283 4.67157 17.9999 5.5 17.9999C6.32843 17.9999 7 17.3283 7 16.4999V15.9999H13V16.4999C13 17.3283 13.6716 17.9999 14.5 17.9999C15.3284 17.9999 16 17.3283 16 16.4999V15.9077C16.5976 15.6972 16.998 15.1334 17 14.4999V12.4999C16.9979 11.8235 16.5428 11.2324 15.8894 11.0575ZM6.37208 6.99988H13.6279L14.8279 10.9999H5.17205L6.37208 6.99988ZM5.5 16.9999C5.77614 16.9999 6 16.776 6 16.4999V15.9999H5V16.4999C5 16.776 5.22386 16.9999 5.5 16.9999ZM15 16.4999C15 16.776 14.7761 16.9999 14.5 16.9999C14.2239 16.9999 14 16.776 14 16.4999V15.9999H15V16.4999ZM15.5 14.9999C15.7761 14.9997 15.9998 14.7759 16 14.4999V12.4999C15.9998 12.2238 15.7761 12.0001 15.5 11.9999H4.5C4.22394 12.0001 4.00019 12.2238 4 12.4999V14.4999C4.00019 14.7759 4.22394 14.9997 4.5 14.9999H15.5Z" fill="#646464"/>
                                <path d="M6.5 12.9999H5.5C5.22386 12.9999 5 13.2237 5 13.4999C5 13.776 5.22386 13.9999 5.5 13.9999H6.5C6.77614 13.9999 7 13.776 7 13.4999C7 13.2237 6.77614 12.9999 6.5 12.9999Z" fill="#646464"/>
                                <path d="M14.5 12.9999H13.5C13.2239 12.9999 13 13.2237 13 13.4999C13 13.776 13.2239 13.9999 13.5 13.9999H14.5C14.7761 13.9999 15 13.776 15 13.4999C15 13.2237 14.7761 12.9999 14.5 12.9999Z" fill="#646464"/>
                            </svg>

                            {lang[params.lang]?.pages.home.freeAirportDelivery || "Free Airport Delivery & Dropoffs"}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Hero;