import { useContext } from 'react';
import {
    Link,
    NavLink,
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import lang from "../../lang/lang.json";

const frFLag =  <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-fr" viewBox="0 0 640 480" width={22} className='me-2'>
                    <path fill="#fff" d="M0 0h640v480H0z"/>
                    <path fill="#000091" d="M0 0h213.3v480H0z"/>
                    <path fill="#e1000f" d="M426.7 0H640v480H426.7z"/>
                </svg>

const ukFLag =  <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-gb" viewBox="0 0 640 480" width={22} className='me-2'>
                    <path fill="#012169" d="M0 0h640v480H0z"/>
                    <path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0z"/>
                    <path fill="#C8102E" d="m424 281 216 159v40L369 281zm-184 20 6 35L54 480H0zM640 0v3L391 191l2-44L590 0zM0 0l239 176h-60L0 42z"/>
                    <path fill="#FFF" d="M241 0v480h160V0zM0 160v160h640V160z"/>
                    <path fill="#C8102E" d="M0 193v96h640v-96zM273 0v480h96V0z"/>
                </svg>



function Header() {
    const params = useParams();
    const location = useLocation();
    // const navigate = useNavigate();
    // let pathname = window.location.pathname;

    const closeMenu = (e) => {
        console.log("closeMenu")
        document.getElementById("menuButton").click();
    }

    const preventCloseMenu = (e) => {
        console.log("menu")
        e.stopPropagation();
    }

    const clickMenuButton = (e) => {
        setTimeout(() => {
            if (document.getElementById("menuButton").classList.contains("collapsed")) {
                // document.body.classList.remove("overflow-hidden");
                document.getElementById("overlay-screen").classList.add("d-none");
                return;
            }
            // document.body.classList.add("overflow-hidden");
            document.getElementById("overlay-screen").classList.remove("d-none");
        }, 100);
    }

    return (<>
        <header className="h-navigation text-white p-0 ">
            <div className="d-flex flex-wrap py-1 py-sm-3 mx-2 mx-lg-5">

                <nav className="navbar">
                    <div className="me-2 ps-1">
                        <button
                            id="menuButton"
                            className="navbar-toggler collapsed p-1 border-0"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarToggleExternalContent"
                            aria-controls="navbarToggleExternalContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={(e) => clickMenuButton(e)}
                        >
                            <svg id="hamburger" className="Header__toggle-svg" viewBox="0 0 60 40" height={20} width={30}>
                                <g stroke="#7A7C7B" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round">
                                    <path id="top-line" d="M10,10 L45,10 Z"></path>
                                    <path id="middle-line" d="M10,20 L45,20 Z"></path>
                                    <path id="bottom-line" d="M10,30 L45,30 Z"></path>
                                </g>
                            </svg>
                        </button>
                    </div>
                </nav>

                <div className="d-flex flex-nowrap align-items-center justify-content-start">
                    <a className="d-flex align-items-center text-white text-decoration-none logo__img"
                        href={params.lang ? `/${params.lang}/` : `en/`}>
                        <img src="../../../images/logo.webp" width="220" height="38" alt="Ola Mauritius company logo" className='header__logo'/>
                    </a>
                </div>
                <div className="header-right ms-auto d-flex flex-wrap gap-4 align-items-center">
                    {params.lang == "fr" ?
                        <a href={location.pathname.replace("/fr", "/en") + location.search} className="btn btn__lang btn__language--header pe-2">
                            {ukFLag}
                            <span>EN</span><span>EUR</span>
                        </a>
                        :
                        !params.lang ?
                            <a href={"/fr" + location.search} className="btn btn__lang pe-2 btn__language--header">
                                {frFLag}
                                <span>FR</span><span>EUR</span>
                            </a>
                            :
                            <a href={location.pathname.replace("/en", "/fr") + location.search} className="btn btn__lang pe-2 btn__language--header">
                                {frFLag}
                                <span>FR</span><span>EUR</span>
                            </a>
                    }
                    <a href={params.lang ? `/${params.lang}/contact-us` : `en/contact-us`} className="btn btn-outline-dark d-none d-md-block">
                        <img src="../../../images/icon-latter.svg" width="22" height="18" alt="latter icon" className="bi" />
                        {lang[params.lang]?.footer.contactUs || "Contact us"}
                    </a>
                </div>
            </div>

            <nav className="navbar navbar-expand-lg navbar-light bg-light p-0">
                <div className="container-fluid p-0">
                    <div className="collapse navbar-collapse align-items-start p-0 bg-transparent vh-100"
                        id="navbarToggleExternalContent"
                        onClick={() => closeMenu()}
                    >
                        <div className="mobile-background p-3 pb-5 px-lg-0 py-lg-1" onClick={(e) => preventCloseMenu(e)}>
                            <div className="container p-0">

                                <div className="header-right ms-auto mb-3 d-flex d-md-none justify-content-end flex-wrap gap-2">
                                    {/* {params.lang == "fr" ?
                                        <a href={location.pathname.replace("/fr", "/en") + location.search} className="btn px-4 px-2 me-auto btn__language--menue">
                                            <i className="bi bi-globe pe-2 h4"></i>
                                            EN/EUR
                                        </a>
                                        :
                                        !params.lang ?
                                            <a href={"/fr" + location.search} className="btn px-4 px-2 me-auto btn__language--menue">
                                                <i className="bi bi-globe pe-2 h4"></i>
                                                FR/EUR
                                            </a>
                                            :
                                            <a href={location.pathname.replace("/en", "/fr") + location.search} className="btn px-2 me-auto btn__language--menue">
                                                <i className="bi bi-globe pe-2 h4"></i>
                                                FR/EUR
                                            </a>
                                    } */}
                                    <Link to={params.lang ? `/${params.lang}/contact-us` : `en/contact-us`} className="btn btn-outline-dark px-4">
                                        <img src="../../../images/icon-latter.svg" alt="latter icon" width="21" height="13" className="bi pe-2" />
                                        Contact Us
                                    </Link>
                                </div>

                                <div className="card bg-white px-2 py-3">
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li className="nav-item">
                                            <Link onClick={() => closeMenu()} to={params.lang ? `/${params.lang}/` : `en/`} className="nav-link active semi-bold" >
                                                {lang[params.lang]?.footer.mauritiusCarRental || "Mauritius Car Rental"}
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link onClick={() => closeMenu()} to={params.lang ? `/${params.lang}/services/airport-transfer` : `en/services/airport-transfer`} className="nav-link text-black semi-bold">
                                                <span>{lang[params.lang]?.footer.airportTransfer || "Airport Transfer"}</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item dropdown semi-bold">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdownAbout" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                {lang[params.lang]?.footer.about || "About"}
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarScrollingDropdownAbout">
                                                <li className="nav-item">
                                                    <Link onClick={() => closeMenu()} to={params.lang ? `/${params.lang}/benefits` : `en/benefits`} className="nav-link">
                                                        <span>Benefits</span>
                                                    </Link>
                                                </li>
                                                {/* <li className="nav-item">
                                                    <Link onClick={() => closeMenu()} to={params.lang ? `/${params.lang}/why-choose-us` : `en/why-choose-us`} className="nav-link">
                                                        <span>Why OLA</span>
                                                    </Link>
                                                </li> */}
                                                <li className="nav-item">
                                                    <Link onClick={() => closeMenu()} to={params.lang ? `/${params.lang}/testimonials` : `en/testimonials`} className="nav-link">
                                                        <span>{lang[params.lang]?.footer.testimonials || "Testimonials"}</span>
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link onClick={() => closeMenu()} to={params.lang ? `/${params.lang}/why-choose-us/faq` : `en/why-choose-us/faq`} className="nav-link">
                                                        <span>{lang[params.lang]?.footer.help || "FAQ’s"}</span>
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link onClick={() => closeMenu()} to={params.lang ? `/${params.lang}/blogs` : `en/blogs`} className="nav-link">
                                                        <span>Blogs</span>
                                                    </Link>
                                                </li>

                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </nav>
        </header>
    </>
    );
}

export default Header;