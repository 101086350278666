import { Link, useLocation, useParams, useSearchParams } from "react-router-dom"
import FinalWrapper from "./FinalWrapper"
import Voucher from "./Voucher"
import { useEffect } from "react"

const Final = () => {
    const location = useLocation()
    useEffect(()=>{
        window.scrollTo({top: 0, left:0, behavior: 'instant'});
    },[])

    return (
        <FinalWrapper title="Thank you" final={true} metaTitle='Thank you - OLA Mauritius' url={`final/${location.search}`}>
            <div className="voucher__wrapper d-none d-sm-block">
                <Voucher />
            </div>
        </FinalWrapper>
    )
}

export default Final