import { useEffect, useRef, useState } from "react"

const hourOptions = []
let hour=0
while(hour < 24){
    let minutes = 0
    while(minutes < 60){
        hourOptions.push(`${hour < 10 ? '0'+hour : hour}:${minutes === 0 ? "00" : minutes}`)
        minutes += 30
    }
    hour++
}


const TimePicker = ({setHour, label, defaultTime}) => {
    const [showSelector, setShowSelector] = useState(false)
    const [time, setTime] = useState(defaultTime)
    const selectorRef = useRef("")

    useEffect(()=>{
        selectorRef.current.classList.add('hide')
    },[])

    const onHourClickHandler = (time) => {
        setTime(time)
        setHour(time)
        onCloseSelect()
    }

    const onOpenSelect = () => {
        setShowSelector(true)
        selectorRef.current.classList.add('show')
    }
    
    const onCloseSelect = () => {
    setShowSelector(false)
    selectorRef.current.addEventListener('animationend', ()=>{
        selectorRef.current.classList.add('hide')
    },{ once:true })
}

    return (
        <div className="ola-timepicker">
            <button className='ola-timepicker__button' onClick={onOpenSelect}>{time}</button>
            {showSelector && <div className="ola-timepicker__overlay" onClick={onCloseSelect}></div>}
            <div className={`ola-timepicker__selector ${showSelector ? 'show' : ''}`} ref={selectorRef}>
                <div className="ola-timepicker__selector--label">
                    <svg className="ola-timepicker__selector--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                        <path d="M50 5C25.2 5 5 25.2 5 50s20.1 45 45 45 45-20.1 45-45S74.8 5 50 5zm0 84.7c-21.9 0-39.7-17.8-39.7-39.7 0-21.9 17.8-39.7 39.7-39.7S89.7 28.1 89.7 50c0 21.9-17.8 39.7-39.7 39.7z"/>
                        <path d="M52.4 51.7V25.2c0-1.3-1.1-2.4-2.4-2.4s-2.4 1.1-2.4 2.4v27.3c0 .3.1.6.1.8.1.4.3.8.6 1.1l19.3 19.3c.9.9 2.5.9 3.4 0 .9-.9.9-2.5 0-3.4L52.4 51.7z"/>
                    </svg>
                    <p className='semi-bold mb-0'>{`24-hour ${label}`}</p>
                </div>
                <div className="ola-timepicker__selector--hours">
                    {hourOptions.map(hour => 
                        <button className="ola-timepicker__hour semi-bold" key={hour} onClick={()=>onHourClickHandler(hour)}>{hour}</button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default TimePicker