import React, { useState, useEffect } from 'react';
import {
    Link,
    useLocation,
    useParams
} from "react-router-dom";

import { getAllLocations } from '../services/apiLocationsService';
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function Locations() {
    const params = useParams();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const [showLocationsPlaceholder, setShowLocationsPlaceholder] = useState(true);
    const [locations, setLocations] = useState(false);
    const [placeholder, setPlaceholder] = useState(false);

    useEffect(() => {
        getPlaceholders();
        if (!locations.length) {
            // Timeout needs to mimic asynchrone Locations loading
            setTimeout(() => {
                getLocations();
            }, 0);
        }
    }, []);

    let getLocations = async () => {
        setShowLocationsPlaceholder(true);
        let results = await getAllLocations(axiosPrivate);
        console.log("locations: ", results)
        results = results?.data
        let locationsTemp = [];
        if (results && results.length > 0) {
            for (let i = 0; i < results.length; i++) {
                locationsTemp.push(
                    <div key={"key-location-" + i} className="col-12 col-lg-4">
                        <div className="">
                            <h5 className="p-1 m-3">
                                <Link
                                    to={params.lang ? `/${params.lang}/${results[i].url || ""}` : `en/${results[i].url || ""}`}
                                    className="link-dark link-offset-3 link-underline-opacity-10 link-underline-opacity-75-hover semi-bold"
                                >   
                                    <span>{results[i].title}</span>
                                </Link>
                            </h5>
                        </div>
                    </div>
                );
            }

            if (locationsTemp.length > 0) {
                setLocations(locationsTemp);
            }
        }
        setShowLocationsPlaceholder(false);
    }

    let getPlaceholders = async () => {
        let placeholderTemp = [];
        for (let i = 0; i < 6; i++) {
            placeholderTemp.push(
                <div key={"key-location-placeholder-" + i} className="col-12 col-lg-4">
                    <div className="accordion-item accordion-item--placeholder">
                        <h2 className="accordion-header">
                            <div className="accordion-button">
                                <div className="title__placeholder">
                                    <div className="load-wraper">
                                        <div className="activity"></div>
                                    </div>
                                </div>
                            </div>
                        </h2>
                    </div>
                </div>
            );
        }

        if (placeholderTemp.length > 0) {
            setPlaceholder(placeholderTemp);
        }
    }

    return (<>
        <section className="locations-section py-4 mb-5">
            <div className="container">

                <div className="text-center p-5">
                    <h2 className="text-center bold">
                        Search Car Rental in Mauritius by Location
                    </h2>
                    <div className="px-md-5 mx-md-5">
                        <span className="">
                            Whether you’re looking for luxury car rentals, SUV rentals, van rentals, pickup truck rentals, convertible car rentals, cargo van rentals or minivan rentals, you’ll find them all on OLA Mauritius.
                        </span>
                    </div>
                </div>
                <div className="accordion accordion__grid" id="search-car-accordion">

                    {!!showLocationsPlaceholder ?
                        <div className="row text-center">

                            {placeholder}

                        </div>
                        :
                        < div className="row text-center">
                            {locations}
                        </div>
                    }
                </div>
            </div>
        </section >
    </>);
}

export default Locations;