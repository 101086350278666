import React, { useState, useEffect, useRef } from 'react';
import {
    useParams,
    useNavigate,
    useLocation
} from 'react-router';
import {
    useSearchParams
} from "react-router-dom";
import Calendar from "./Calendar";

import lang from "../lang/lang.json";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import blogs from './../api/data/json/blogs.json'

function SearchForm({setPickup, setDropOff, setDiff}) {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const axiosPrivate = useAxiosPrivate();
    const [pickupLoc, setPickuploc] = useState('airport');
    const [pickupPlaceholder, setPickupPlaceholder] = useState('Pick up & Return location');
    const [dropOffLoc, setDropOffLoc] = useState('airport');
    const [diffLoc, setDiffLoc] = useState(false);
    const [sameReturnLocationVisible, setSameReturnLocationVisible] = useState(false);
    const [age, setAge] = useState('26');
    const [dates, setDate] = useState("");
    const [times, setTimes] = useState("");
    const [isSearchDisabled, setIsSearchDisabled] = useState(false);
    const returnLocationBtnRef = useRef()

    function handleDate(dates) {
        console.log("dates", dates);
        setDate(dates);
    }

    function handleTimes(times) {
        console.log("times", times);
        setTimes(times);
    }

    const changeLocationHandler = (location, secondLocation, setLocation, setCheckoutLocation) => {
        setLocation(location)
        setCheckoutLocation && setCheckoutLocation(location)
        if(location === secondLocation && diffLoc){
            toggleSameReturnLocBtn()
        }
    }

    useEffect(() => {

        if (searchParams.get('pickupLoc')) {
            setPickuploc(searchParams.get('pickupLoc'))
        }

        if (searchParams.get('dropoffLoc')) {
            setDropOffLoc(searchParams.get('dropoffLoc'))
        }
        
        if (searchParams.get('diffLoc') && searchParams.get('diffLoc') === "1") {
            setDiffLoc(searchParams.get('diffLoc'));
            toggleSameReturnLocBtn()
        }

        if (searchParams.get('age')) {
            setAge(searchParams.get('age'))
        }

        if (!searchParams.get('start') || !searchParams.get('end')) {
            return;
        }

        let startDate = new Date(searchParams.get('start'));
        startDate = startDate.toLocaleDateString('en-CA');
        // setPickupTime(searchParams.get('start').substring(11, 16));

        let endDate = new Date(searchParams.get('end'));
        endDate = endDate.toLocaleDateString('en-CA');
        // setDropOffTime(searchParams.get('end').substring(11, 16));

        setTimes({pickUpTime: searchParams.get('start').substring(11, 16), returnTime: searchParams.get('end').substring(11, 16)});
        setDate({
            from: startDate,
            to: endDate
        })
    }, [searchParams]);

    useEffect(() => {
        // console.log("pickupLoc: ", pickupLoc)
        // console.log("dropOffLoc: ", dropOffLoc)
        // console.log("diffLoc: ", diffLoc)
        // console.log("dates: ", dates)
        // console.log("times: ", times)
        // console.log("age: ", age)

        if (!pickupLoc || !dates.from || !dates.to || !times.pickUpTime || !times.returnTime || (diffLoc && !dropOffLoc)) {
            setIsSearchDisabled(true);
            return;
        }

        let diff = Math.floor((Date.parse(dates.to) - Date.parse(dates.from)) / 86400000)
        if (diff < 3) {
            setIsSearchDisabled(true);
            return;
        }

        setIsSearchDisabled(false);

    }, [pickupLoc, dropOffLoc, diffLoc, dates, times, age]);

    const toggleSameReturnLocBtn = () => {
        let sameReturnLocationToggleDesk = document.getElementById("sameReturnLocationToggleDesk")
        console.log("sameReturnLocationToggleDesk ", sameReturnLocationToggleDesk)
        setSameReturnLocationVisible(!sameReturnLocationVisible)
        if (sameReturnLocationVisible) {
            setPickupPlaceholder('Pick up location');
            sameReturnLocationToggleDesk.classList.add("collapsed--desk");
            returnLocationBtnRef.current.classList.add("collapsed")
            setDiffLoc(false);
            setDiff && setDiff(false)
        } else {
            setPickupPlaceholder('Pick up & Return location');
            sameReturnLocationToggleDesk.classList.remove("collapsed--desk");
            returnLocationBtnRef.current.classList.remove("collapsed")
            setDiffLoc(true);
            
            setDiff && setDiff(true)
        }

    }

    const handleSubmit = () => {

        // console.log("pickupLoc: ", pickupLoc)
        // console.log("dropOffLoc: ", dropOffLoc)
        // console.log("dates: ", dates)
        // console.log("times: ", times)
        // console.log("diffLoc: ", diffLoc)
        // console.log("age: ", age)

        if (!pickupLoc || !dates.from || !dates.to || (diffLoc && !dropOffLoc)) {
            return;
        }

        let url = params.lang ? `/${params.lang}/` : `/en/`;
        url += "results"
        let differentLoc = diffLoc ? 1 : 0;
        let pickDate = new Date(`${dates.from}T${times.pickUpTime}Z`).toISOString();
        let dropOffDate = new Date(`${dates.to}T${times.returnTime}Z`).toISOString();
        let searchParamsTemp = `?start=${pickDate}&end=${dropOffDate}&pickupLoc=${pickupLoc}&dropoffLoc=${dropOffLoc}&diffLoc=${differentLoc}&age=${age}`;
        url += searchParamsTemp;

        let carId = searchParams.get("id");
        const locationBlogs = blogs.data.en.filter(blog => blog.type === 'location')

        if (!!carId && !locationBlogs.some(blog => blog.url === carId)) {
            url += `&id=${carId}`
        }

        let options = searchParams.get("options");
        if (!!options) {
            url += `&options=${options}`
        }

        navigate(url);
        navigate(0)

    };

    return (
        <>
            <div className="hero-form" onSubmit={handleSubmit} id="form-home">
                <div className="row">
                    <div className="col-xl-6 pt-4 mb-4 mb-xl-0">
                        <div className="d-flex flex-wrap justify-content-between">
                            <InputLabel htmlFor="pickuploc" className="font-weight-bold">
                                {lang[params.lang]?.searchForm.pickUpLocation || "Pick up & Return Location"}
                            </InputLabel>
                            <button type="button"
                                className="btn btn-with-img-toggle d-flex align-items-center p-0 mb-2 collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#sameReturnLocationToggle"
                                aria-controls="sameReturnLocationToggle"
                                aria-expanded="false"
                                aria-label="Toggle same return location"
                                ref={returnLocationBtnRef}
                                onClick={() => toggleSameReturnLocBtn()}>
                                <img src="../../../images/icon-checkbox.svg" alt="mountain banner" aria-hidden="true" />
                                <span className="img-toggle"></span>
                                <span className="hero__checkbox">
                                    {lang[params.lang]?.searchForm.sameReturnLocation || "Same return location"}
                                </span>
                            </button>
                        </div>
                        <div className="d-block d-xl-flex">
                            {/* <div className="input-location-wrp w-100 mb-4 mb-xl-0">
                                        <input
                                            name="pickuploc"
                                            id="pickuploc"
                                            className="w-100 input-wit-geo-icon ps-5 pe-2"
                                            placeholder={pickupPlaceholder}
                                            onChange={(e) => setPickuploc(e.target.value)}
                                            value={pickupLoc}
                                        />
                                    </div> */}
                            <div className="input-location-wrp w-100 mb-4 mb-xl-0">
                                {/* <input
                                            name="pickuploc"
                                            id="pickuploc"
                                            className="w-100 input-wit-geo-icon ps-5 pe-2"
                                            placeholder={pickupPlaceholder}
                                            onChange={(e) => setPickuploc(e.target.value)}
                                            value={pickupLoc}
                                        /> */}
                                <div className="w-100 input-wit-geo-icon">
                                    <svg className="input__icon" width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0 7.5C0 3.364 3.353 0 7.474 0C11.596 0 14.948 3.364 14.948 7.5C14.948 12.672 8.234 20.138 8.166 20.212L7.474 21.019L6.706 20.123C5.133 18.293 0 11.975 0 7.5ZM7.474 1.914C10.554 1.914 13.06 4.408 13.06 7.474C13.06 10.937 9.018 16.21 7.474 18.096C5.93 16.211 1.888 10.941 1.888 7.5C1.89186 4.41654 4.39054 1.91786 7.474 1.914ZM11.121 7.5C11.1193 9.5135 9.4875 11.1453 7.474 11.147C5.46089 11.1448 3.82965 9.51311 3.828 7.5C3.8302 5.48728 5.46128 3.8562 7.474 3.854C9.48711 3.85565 11.1188 5.48689 11.121 7.5ZM9.233 7.501C9.233 6.531 8.444 5.741 7.474 5.741C6.504 5.741 5.716 6.53 5.716 7.5C5.71087 7.9679 5.8944 8.41814 6.22518 8.74911C6.55596 9.08008 7.0061 9.26387 7.474 9.259C7.9419 9.26414 8.39214 9.0806 8.72311 8.74982C9.05408 8.41904 9.23787 7.9689 9.233 7.501Z" fill="#009900"/>
                                    </svg>
                                    <FormControl sx={{ minWidth: '100%' }} size='medium'>
                                        <Select className="form-select custom-select"
                                            aria-label="Pick Up Location"
                                            name="pickuploc"
                                            id="pickuploc"
                                            fullWidth={true}
                                            onChange={(e) => changeLocationHandler(e.target.value, dropOffLoc, setPickuploc, setPickup)}
                                            value={pickupLoc}
                                            MenuProps={{
                                                disableScrollLock: true,
                                            }}
                                            sx={{
                                                minWidth:'100%',
                                                border: 'none',
                                                padding: 0,
                                                overflowX: 'hidden',
                                                '@media (min-width: 1200px)': {
                                                    minWidth: '100%',
                                                },
                                                "& .MuiInputBase-input": {
                                                    padding: '18.5px 40px 18.5px 60px',
                                                    '@media only screen and (min-width: 1200px)': {
                                                        padding: '20.5px 0px 20.5px 60px !important',
                                                        minWidth: '100%',
                                                    },
                                                },
                                                "& .MuiSvgIcon-root": {
                                                    display: "none"
                                                }
                                                }}

                                        >   
                                            <MenuItem value="airport" defaultValue>Airport, Mauritius</MenuItem>
                                            <MenuItem value="hotel">Hotel Delivery</MenuItem>
                                            <MenuItem value="other">Other Location</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {/* Mobile Dropoff Location*/}
                            <div className="mob d-block d-xl-none w-100">
                                <div className="input-location-wrp collapse collapse" id="sameReturnLocationToggle">
                                    {/* <input name="dropoffloc"
                                                id="dropoffloc"
                                                className="w-100 input-wit-geo-icon ps-5 pe-2"
                                                placeholder="Dropoff Location"
                                                onChange={(e) => setDropOff(e.target.value)}
                                                value={dropOff} /> */}

                                    <div className="w-100 input-wit-geo-icon">
                                        <svg className="input__icon" width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M0 7.5C0 3.364 3.353 0 7.474 0C11.596 0 14.948 3.364 14.948 7.5C14.948 12.672 8.234 20.138 8.166 20.212L7.474 21.019L6.706 20.123C5.133 18.293 0 11.975 0 7.5ZM7.474 1.914C10.554 1.914 13.06 4.408 13.06 7.474C13.06 10.937 9.018 16.21 7.474 18.096C5.93 16.211 1.888 10.941 1.888 7.5C1.89186 4.41654 4.39054 1.91786 7.474 1.914ZM11.121 7.5C11.1193 9.5135 9.4875 11.1453 7.474 11.147C5.46089 11.1448 3.82965 9.51311 3.828 7.5C3.8302 5.48728 5.46128 3.8562 7.474 3.854C9.48711 3.85565 11.1188 5.48689 11.121 7.5ZM9.233 7.501C9.233 6.531 8.444 5.741 7.474 5.741C6.504 5.741 5.716 6.53 5.716 7.5C5.71087 7.9679 5.8944 8.41814 6.22518 8.74911C6.55596 9.08008 7.0061 9.26387 7.474 9.259C7.9419 9.26414 8.39214 9.0806 8.72311 8.74982C9.05408 8.41904 9.23787 7.9689 9.233 7.501Z" fill="#009900"/>
                                        </svg>
                                       <FormControl sx={{ minWidth: '100%' }} size='medium'>
                                        <Select className="form-select custom-select"
                                            aria-label="Drop Off Location"
                                            name="dropoffloc"
                                            id="dropofflocDesk"
                                            onChange={(e) => changeLocationHandler(e.target.value, pickupLoc, setDropOffLoc, setDropOff)}
                                            fullWidth={true}
                                            value={dropOffLoc}
                                            MenuProps={{
                                                disableScrollLock: true,
                                            }}
                                            sx={{
                                                minWidth:'100%',
                                                border: 'none',
                                                padding: 0,
                                                overflowX: 'hidden',
                                                '@media (min-width: 1200px)': {
                                                    minWidth: '100%',
                                                },
                                                "& .MuiInputBase-input": {
                                                    padding: '18.5px 40px 18.5px 60px',
                                                    '@media only screen and (min-width: 1200px)': {
                                                        padding: '20.5px 0px 20.5px 60px !important',
                                                        minWidth: '100%',
                                                    },
                                                },
                                                "& .MuiSvgIcon-root": {
                                                    display: "none"
                                                }
                                                }}
                                        >   
                                            <MenuItem value="airport" defaultValue>Airport, Mauritius</MenuItem>
                                            <MenuItem value="hotel">Hotel Delivery</MenuItem>
                                            <MenuItem value="other">Other Location</MenuItem>
                                        </Select>
                                    </FormControl>
                                    </div>
                                </div>
                            </div>

                            {/* Desktop Dropoff Location*/}
                            <div className="desk input-location-wrp me-4 collapsible--desk collapsed--desk " id="sameReturnLocationToggleDesk">
                                {/* <input name="dropoffloc"
                                            id="dropoffloc"
                                            className="w-100 input-wit-geo-icon ps-5 pe-2 d-none d-xl-block ms-4"
                                            placeholder="Dropoff Location"
                                            onChange={(e) => setDropOff(e.target.value)}
                                            value={dropOff} /> */}
                                <div className="w-100 input-wit-geo-icon d-none d-xl-block ms-4">
                                    <svg className="input__icon" width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0 7.5C0 3.364 3.353 0 7.474 0C11.596 0 14.948 3.364 14.948 7.5C14.948 12.672 8.234 20.138 8.166 20.212L7.474 21.019L6.706 20.123C5.133 18.293 0 11.975 0 7.5ZM7.474 1.914C10.554 1.914 13.06 4.408 13.06 7.474C13.06 10.937 9.018 16.21 7.474 18.096C5.93 16.211 1.888 10.941 1.888 7.5C1.89186 4.41654 4.39054 1.91786 7.474 1.914ZM11.121 7.5C11.1193 9.5135 9.4875 11.1453 7.474 11.147C5.46089 11.1448 3.82965 9.51311 3.828 7.5C3.8302 5.48728 5.46128 3.8562 7.474 3.854C9.48711 3.85565 11.1188 5.48689 11.121 7.5ZM9.233 7.501C9.233 6.531 8.444 5.741 7.474 5.741C6.504 5.741 5.716 6.53 5.716 7.5C5.71087 7.9679 5.8944 8.41814 6.22518 8.74911C6.55596 9.08008 7.0061 9.26387 7.474 9.259C7.9419 9.26414 8.39214 9.0806 8.72311 8.74982C9.05408 8.41904 9.23787 7.9689 9.233 7.501Z" fill="#009900"/>
                                    </svg>
                                    <FormControl sx={{ minWidth: '100%' }} size='medium'>
                                        <Select className="form-select custom-select"
                                            aria-label="Drop Off Location"
                                            name="dropoffloc"
                                            id="dropofflocDesk"
                                            onChange={(e) => changeLocationHandler(e.target.value, pickupLoc, setDropOffLoc, setDropOff)}
                                            fullWidth={true}
                                            value={dropOffLoc}
                                            MenuProps={{
                                                disableScrollLock: true,
                                            }}
                                            sx={{
                                                minWidth:'100%',
                                                border: 'none',
                                                padding: 0,
                                                overflowX: 'hidden',
                                                '@media (min-width: 1200px)': {
                                                    minWidth: '100%',
                                                },
                                                "& .MuiInputBase-input": {
                                                    padding: '18.5px 40px 18.5px 60px',
                                                    '@media only screen and (min-width: 1200px)': {
                                                        padding: '20.5px 0px 20.5px 60px !important',
                                                        minWidth: '100%',
                                                    },
                                                },
                                                "& .MuiSvgIcon-root": {
                                                    display: "none"
                                                }
                                                }}
                                        >   
                                            <MenuItem value="airport" defaultValue>Airport, Mauritius</MenuItem>
                                            <MenuItem value="hotel">Hotel Delivery</MenuItem>
                                            <MenuItem value="other">Other Location</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-3 pt-4">
                                <label htmlFor="pickupdate" className="font-weight-bold">Pick up date & time</label>
                                <div className="datetime-input-wrp d-flex">
                                    <input type="" name="pickupdate" id="pickupdate"
                                        className="w-100 input-wit-calendar-icon ps-5 pe-2 border-0"
                                        placeholder="Pick up date" />
                                    <div className="vr"></div>
                                    <input type="" name="pickuptime" id="pickuptime"
                                        className="w-50 px-2 border-0"
                                        placeholder="Time" />
                                </div>
                            </div>
                            <div className="col-md-3 pt-4">
                                <label htmlFor="returndate" className="font-weight-bold">Return date & time</label>
                                <div className="datetime-input-wrp d-flex">
                                    <input type="" name="returndate" id="returndate"
                                        className="w-100 input-wit-calendar-icon ps-5 pe-2 border-0"
                                        placeholder="Return date" />
                                    <div className="vr"></div>
                                    <input type="" name="returntime" id="returntime"
                                        className="w-50 px-2 border-0"
                                        placeholder="Time" />
                                </div>
                            </div> */}

                    <Calendar datesData={dates} timesData={times} getDates={handleDate} getTimes={handleTimes} />
                </div>

                <div className="row">
                    <div className="col-xl-6 d-flex align-items-center pt-4 ps-3 mt-2">
                        <div className="d-block text-nowrap age-select">
                            I am
                        </div>
                        <Select
                            className="form-select custom-select border-0"
                            aria-label="Select age"
                            value={age} 
                            onChange={(e) => setAge(e.target.value)}
                            MenuProps={{
                                                disableScrollLock: true,
                                            }}
                            sx={{
                                padding: 0,
                                flex: '0 1 80px',
                                '@media (min-width: 1200px)': {
                                    minWidth: '76px',
                                },
                                '& svg': {
                                    display: 'none'
                                },
                            }}
                        >    
                            <MenuItem value="18">18+</MenuItem>
                            <MenuItem value="22">22+</MenuItem>
                            <MenuItem defaultValue value="26">26+</MenuItem>
                        </Select>
                    </div>
                    <div className="col-xl-3 ms-auto pt-4 mt-2">
                        <button id="searchFormSubmitBtn" className="btn btn-primary fw-bold w-100"
                            onClick={() => handleSubmit()}
                            disabled={isSearchDisabled}>
                            {lang[params.lang]?.searchForm.search || "Search"}
                        </button>
                    </div>
                </div>
            </div>

        </>
    );
}

export default SearchForm;