const formatDate = (date, formatType) => {
    const year = date.getFullYear()
    const month = date.getMonth().toString().length === 1 ? `0${date.getMonth()+1}` : date.getMonth()+1
    const day = date.getDate().toString().length === 1 ? `0${date.getDate()}` : date.getDate()
    const hour = date.getHours().toString().length === 1 ? `0${date.getHours()}` : date.getHours()
    const minutes = date.getMinutes().toString().length === 1 ? `0${date.getMinutes()}` : date.getMinutes()
    const seconds = date.getSeconds().toString().length === 1 ? `0${date.getSeconds()}` : date.getSeconds()
    
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    return formatType
    .replace("YYYY", `${year}`)
    .replace("YY", `${year}`.slice(-2))
    .replace("MMM", `${months[month-1]}`)
    .replace("MM", `${month}`)
    .replace("DD", `${day}`)
    .replace("hh", `${hour}`)
    .replace("mm", `${minutes}`)
    .replace("ss", `${seconds}`)
}

const isValidDate = (date) => {
    if(date === null){
        return null
    }
    return date.toDateString === new Date(date).toDateString
}

export {
    formatDate,
    isValidDate
}