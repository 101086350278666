import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from "react-router-dom";

import { getBlogs } from '../services/apiBlogsService';
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function Posts() {
    const params = useParams();
    const axiosPrivate = useAxiosPrivate();
    const [showPostsPlaceholder, setShowPostsPlaceholder] = useState(true);
    const [posts, setPosts] = useState(false);
    const [placeholder, setPlaceholder] = useState(false);

    useEffect(() => {
        getPlaceholders();
        if (!posts.length) {
            // Timeout needs to mimic asynchrone posts loading
            setTimeout(() => {
                getPosts();
            }, 0);
        }
    }, []);

    let getPosts = async () => {
        setShowPostsPlaceholder(true);
        let results = await getBlogs(axiosPrivate);
        results = results?.data;
        // use "en" records when there are no records for this lang param
        if (params.lang && results[params.lang]?.length > 0) {
            results = results[params.lang];
        } else {
            results = results["en"];
        }
        let postsTemp = [];
        if (results && results.length > 0) {
            let resultslength = results.length < 4 ? results.length : 4;
            for (let i = 0; i < resultslength; i++) {
                postsTemp.push(
                    <div key={"key-post-" + i} className="col-12 col-lg-6 p-4">
                        <Link to={params.lang ?`/${params.lang}/blog/?id=${results[i].url}`:`/en/blog/?id=${results[i].url}`} className="text-decoration-none">
                            <div className="card card__horizontal">
                                <img className="card-img" src={results[i].imgUrl} alt={results[i].imgAlt || results[i].title} width="249.062" height="192" loading="lazy"/>
                                <div className="card-body">
                                    <div className="card-date">{results[i].date}</div>
                                    <h5 className="card-title bold">{results[i].title}</h5>
                                    <p className="card-text">
                                        {results[i].text}
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </div>
                );
            }

            if (postsTemp.length > 0) {
                setPosts(postsTemp);
            }
        }
        setShowPostsPlaceholder(false);
    }

    let getPlaceholders = async () => {
        let placeholderTemp = [];
        for (let i = 0; i < 4; i++) {
            placeholderTemp.push(
                <div key={"key-post-placeholder-" + i} className="col-12 col-lg-6 p-4">
                    <div className="card card__horizontal card__horizontal--plcaseholder">
                        <div className="card-img">
                            <div className="load-wraper">
                                <div className="activity"></div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="card-date">
                                <div className="load-wraper">
                                    <div className="activity"></div>
                                </div></div>
                            <h5 className="card-title">
                                <div className="load-wraper">
                                    <div className="activity"></div>
                                </div></h5>
                            <div className="card-text">
                                <div className="load-wraper">
                                    <div className="activity"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (placeholderTemp.length > 0) {
            setPlaceholder(placeholderTemp);
        }
    }

    return (
        <section className="posts-section py-4">
            <div className="container p-0">

                {!!showPostsPlaceholder ?
                    <div className="row justify-content-around">

                        {placeholder}

                    </div>
                    :
                    < div className="row justify-content-around">
                        {posts}
                        
                    </div>
                }

                <div className="text-center p-4">
                    <Link to={params.lang ? `/${params.lang}/blogs` : `en/blogs`} className="btn btn-outline-dark px-4 semi-bold">
                        View more posts
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default Posts;