import React, { useState, useEffect } from 'react';
import {
    Link,
    useParams,
    useLocation,
    useSearchParams,
    useOutletContext
} from "react-router-dom";
import { Helmet } from "react-helmet";

import { postDriverData } from '../../services/apiCarsService';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import "react-phone-input-2/lib/bootstrap.css";
import FinalWrapper from './FinalWrapper';

function DriverForm() {
    const axiosPrivate = useAxiosPrivate();
    const params = useParams();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    console.log(searchParams.get('fullname'));
    const [submitted, setSubmitted] = useState(false)
    const [formData, setFormData] = useState({
        fullName: searchParams.get('fullname') ?? '',
        email: searchParams.get('email') ?? '',
        passportNumber: '',
        driverLicenseNumber: '',
    })

    console.log(searchParams.get('fullName'));

    const onChangeHandler = (e) => {
        const stateIdentifier = e.target.getAttribute('name')
        setFormData(prevState => {
            return {
                ...prevState,
                [stateIdentifier]: e.target.value
            }
        })
    }
    
    const onSubmitHandler = async (e) => {
        e.preventDefault();
        const orderId = searchParams.get('id').split('-')[1]
        const formData = new FormData()
        formData.append('passportFile', e.target.passportFile.files[0])
        formData.append('driverLicenseFile', e.target.driverLicenseFile.files[0])
        const responce = await postDriverData(axiosPrivate, formData, orderId);
        setSubmitted(responce.data.success)
    }


    return (
        <div className="col-lg-8">

            <form onSubmit={onSubmitHandler} className='driver-profile__form'>
                <div className="card card__border p-3 my-4">
                    <div className="card-body">
                        <h5 className="bold pb-2">
                            Reservation Number
                        </h5>
                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor="orderId" className="bold mt-3 mb-2">Reservation Number</label>
                                <input type="text" name="orderId" className="input__checkout w-100" id='orderId'
                                    value={searchParams.get('id')}
                                    disabled
                                ></input>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card card__border p-3 my-4">
                    <div className="card-body">
                        <h5 className="bold pb-2">
                            Driver's Contact
                        </h5>
                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="fullName" className="bold mt-3 mb-2">Full Name*</label>
                                <input type="text" name="fullName" className="input__checkout w-100" id='fullName'
                                    value={formData.fullName} onChange={onChangeHandler}
                                    required
                                ></input>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="email" className="bold mt-3 mb-2">Email Address*</label>
                                <input type="email" name="email" className="input__checkout w-100" id='email'
                                    value={formData.email} onChange={onChangeHandler}
                                    required
                                ></input>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card card__border p-3 my-4">
                    <div className="card-body">
                        <h5 className="bold pb-2">
                            Document Upload
                        </h5>
                        <p>
                            Please upload an optimized high resolution scan of your passport and driving license document
                        </p>
                        <div className="row">                
                            <div className="col-md-6">
                                <label htmlFor="passportFile" className="bold mt-3 mb-2">Passport Upload</label>
                                <input type="file" name="passportFile" id='passportFile' className="driver-profile__input--file input__checkout w-100 input--number-as-text"
                                    accept=".png, .jpg, .pdf"
                                    required
                                ></input>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="driverLicenseFile" className="bold mt-3 mb-2">Driving License Upload</label>
                                <input type="file" name="driverLicenseFile" id='driverLicenseFile' className="driver-profile__input--file input__checkout w-100 input--number-as-text"
                                    accept=".png, .jpg, .pdf"
                                    required
                                ></input>
                            </div>
                        </div>
                        <div className='row'>
                            <span className='input__file--span mt-3'>Accepted file types: jpg, png, pdf.</span>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6 col-md-3 mx-auto me-md-0'>
                        <button className='mt-0 mb-4 mb-lg-0 btn btn-primary w-100 change__color--btn' disabled={submitted}>{submitted ? 'Done' : 'Submit'}</button>
                    </div>

                </div>
            </form>
        </div>
    );
}

const DriverProfile = () => {
    const location = useLocation()
    return (
        <FinalWrapper title="Driver Profile Form" metaTitle='Driver Profile Form - OLA Mauritius' url={`driver-form/${location.search}`}>
            <DriverForm />
        </FinalWrapper>
    )
}

export default DriverProfile