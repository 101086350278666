import React, { useState, useEffect } from 'react';
import {
    Link,
    useParams,
    useLocation,
    useSearchParams,
} from "react-router-dom";
import { Helmet } from "react-helmet";

import { getAllCars } from '../services/apiCarsService';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import SearchForm from "../components/SearchForm";
import MetaTags from '../utils/MetaTags';

function Results() {
    const params = useParams();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const axiosPrivate = useAxiosPrivate();
    const [pickupLoc, setPickupLoc] = useState('');
    const [dropOffLoc, setDropOffLoc] = useState('');
    const [diffLoc, setDiffLoc] = useState(false);
    const [pickupDate, setPickupDate] = useState('');
    const [dropOffDate, setDropOffDate] = useState('');
    const [pickupTime, setPickupTime] = useState('');
    const [dropOffTime, setDropOffTime] = useState('');
    const [showCarsPlaceholder, setShowCarsPlaceholder] = useState(true);
    const [cars, setCars] = useState(false);
    const [placeholder, setPlaceholder] = useState(false);
    // fetchedResults is boolean fast when initial for the first then accepts array of vehicles
    const [fetchedResults, setFetchedResults] = useState(false);
    const [transmissionsFilterSelect, setTransmissionsFilterSelect] = useState(false);
    const [categoryFilterSelect, setCategoryFilterSelect] = useState(false);
    const [transmissionsFilter, setTransmissionsFilter] = useState(false);
    const [vehicleCategory, setVehicleCategory] = useState(false);
    const [isFormInEdit, setIsFormInEdit] = useState(false);

    useEffect(()=>{
        window.scrollTo({top: 0, left:0, behavior: 'instant'});
    },[])

    useEffect(() => {

        let pickupLoc = searchParams.get('pickupLoc');
        switch (pickupLoc) {
            case "airport":
                setPickupLoc("Airport, Mauritius");
                break;
            case "hotel":
                setPickupLoc("Hotel Delivery");
                break;
            case "other":
                setPickupLoc("Other Location");
                break;
            default:
                setPickupLoc(pickupLoc);
                break;
        }
        let dropoffLoc = searchParams.get('dropoffLoc');
        switch (dropoffLoc) {
            case "airport":
                setDropOffLoc("Airport, Mauritius");
                break;
            case "hotel":
                setDropOffLoc("Hotel Delivery");
                break;
            case "other":
                setDropOffLoc("Other Location");
                break;
            default:
                setDropOffLoc(dropoffLoc);
                break;
        }
        setDiffLoc(searchParams.get('diffLoc') === "1");

        let startDate = new Date(searchParams.get('start'));
        setPickupDate(startDate.toLocaleDateString('en-CA'));
        setPickupTime(searchParams.get('start').substring(11, 16));

        let endDate = new Date(searchParams.get('end'));
        setDropOffDate(endDate.toLocaleDateString('en-CA'));
        setDropOffTime(searchParams.get('end').substring(11, 16));

    }, [searchParams]);

    useEffect(() => {
        getPlaceholders();
        if (!cars.length) {
            // Timeout needs to mimic asynchrone cars loading
            fetchCars();
            setTimeout(() => {
            }, 0);
        }
    }, [searchParams]);

    useEffect(() => {
        console.log('transmissionsFilter: ', transmissionsFilter)
        // if (!transmissionsFilter) {
        //     return;
        // }
        console.log('transmissionsFilter Filtering');
        // setShowCarsPlaceholder(true);
        getCars();
        setTimeout(() => {
        }, 0);
    }, [transmissionsFilter]);

    useEffect(() => {
        console.log('vehicleCategory: ', vehicleCategory)
        // if (!vehicleCategory) {
        //     return;
        // }
        console.log('vehicleCategory Filtering');
        // setShowCarsPlaceholder(true);
        getCars();
        setTimeout(() => {
        }, 0);
    }, [vehicleCategory]);

    useEffect(() => {
        console.log('fetchedResults: ', fetchedResults)
        if (!fetchedResults) {
            return;
        }
        getCars();
        setTimeout(() => {
        }, 0);
    }, [fetchedResults]);
    

    let fetchCars = async () => {
        setShowCarsPlaceholder(true);

        if (!location.search) {
            return;
        }

        let results = await getAllCars(axiosPrivate, location.search);
        setFetchedResults(results);
        console.log("results: ", results)
        console.log("fetchedResults: ", fetchedResults)

        if (!results || !results.data?.vehicles?.length > 0) {
            return;
        }

        var transmissionsFilterSelectValues = [];
        var categoryFilterSelectValues = [];

        results.data.vehicles.forEach(vehicle => {
            transmissionsFilterSelectValues.push(vehicle.gearbox);
            categoryFilterSelectValues.push(vehicle.category);
        });

        transmissionsFilterSelectValues = Array.from([...new Set(transmissionsFilterSelectValues)]);
        categoryFilterSelectValues = Array.from([...new Set(categoryFilterSelectValues)]);
        console.log("transmissionsFilterSelectValues: ", transmissionsFilterSelectValues)
        console.log("categoryFilterSelectValues: ", categoryFilterSelectValues)

        let transmissionsFilterSelectTemp =
            <select className="form-select custom-select select-filters semi-bold"
                aria-label="Select Transmissions"
                onChange={(e) => setTransmissionsFilter(e.target.value)}>
                <option defaultValue value="">All Transmissions</option>
                {/* <option value="Automatic">Automatic</option>
                <option value="Manual">Manual</option> */}
                {transmissionsFilterSelectValues.map((value) => {
                    return <option key={value} value={value}>{value}</option>;
                })}
            </select>

        setTransmissionsFilterSelect(transmissionsFilterSelectTemp)

        let categoryFilterSelectTemp =
            <select className="form-select custom-select select-filters semi-bold"
                aria-label="Select Vehicle Category"
                onChange={(e) => setVehicleCategory(e.target.value)}>
                <option defaultValue value="">All Vehicle Category</option>
                {categoryFilterSelectValues.map((value) => {
                    return <option key={value} value={value}>{value}</option>;
                })}
            </select>

        setCategoryFilterSelect(categoryFilterSelectTemp)

        setShowCarsPlaceholder(false);
    }

    let getCars = async () => {
        // setShowCarsPlaceholder(true);

        // if (!location.search) {
        //     return;
        // }

        // let results = await getAllCars(axiosPrivate, location.search);
        // console.log("Cars: ", results)

        if (!fetchedResults) {
            return;
        }
        let results = fetchedResults;

        let duration = results?.data?.duration?.days
        results = results?.data?.vehicles
        // transmissionsFilter 1 = Automatic Transmissions
        // transmissionsFilter 2 = Manual Transmissions
        if (transmissionsFilter) {
            console.log("transmissionsFilter: ", transmissionsFilter)
            results = results.filter((result) => result.gearbox.toLowerCase() === transmissionsFilter.toLowerCase());
        }
        console.log("transmissionsFilter Cars: ", results)
        // vehicleCategory 1 = Automatic Transmissions
        // vehicleCategory 2 = Manual Transmissions
        if (vehicleCategory) {
            results = results.filter((result) => result.category.toLowerCase() === vehicleCategory.toLowerCase());
        }
        console.log("vehicleCategory Cars: ", results)

        if (!results || results.length < 1) {
            setShowCarsPlaceholder(false);
            let noResults = <div className="col-12">No Results. Please change criterias of search</div>
            setCars(noResults);
            return;
        }

        let carsTemp = [];
        for (let i = 0; i < results.length; i++) {
            carsTemp.push(
                <div key={"key-car-" + i} className="col-12">
                    <div className="card card__car">
                        <div className="card-body d-flex">
                            <div className="card-column">
                                <img className="card-img" src={results[i].photoMain} alt="Vehicle image" />
                                <div className="card-extras p-2">
                                    <div className="card-extra d-flex">
                                        <i className="bi bi-check"></i>
                                        {results[i].gearbox}
                                    </div>
                                    <div className="card-extra">
                                        <i className="bi bi-check"></i>
                                        Air Conditioning
                                    </div>
                                </div>
                            </div>
                            <div className="card-column">
                                <h5 className="card-title semi-bold">{results[i].make}</h5>
                                <div className="card-type">{results[i].category}</div>
                                {/* <div className="card-free-driver text-center p-1 mt-5 d-lg-none">
                                    Free additional driver
                                </div> */}
                            </div>
                            <div className="card-column card-details">
                                <div className="card-detail d-flex pb-2">
                                    <i className="bi bi-airplane-fill mx-2"></i>
                                    <div className="card-detail">
                                        <span className="card-detail-label d-block">
                                            Pick-Up Location:
                                        </span>
                                        <span className="card-detail-text">
                                            In Terminal
                                        </span>
                                    </div>
                                </div>
                                <div className="card-detail d-flex pb-2">
                                    <i className="bi bi-fuel-pump-fill mx-2"></i>
                                    <div className="card-detail">
                                        <span className="card-detail-label d-block">
                                            Fuel Policy:
                                        </span>
                                        <span className="card-detail-text">
                                            Full to Full
                                        </span>
                                    </div>
                                </div>
                                <div className="card-detail d-flex pb-2">
                                    <i className="bi bi-speedometer2 mx-2"></i>
                                    <div className="card-detail">
                                        <span className="card-detail-label d-block">
                                            Mileage:
                                        </span>
                                        <span className="card-detail-text">
                                            Unlimited
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="card-column">
                                {/* <div className="card-free-driver d-flex text-center p-1 mb-4 d-none d-lg-block">
                                    Free additional driver
                                </div> */}
                                <div className="card-free d-flex pb-2">
                                    <div className="card-free-title d-block">
                                        Included for Free
                                    </div>
                                </div>
                                <div className="card-free d-flex pb-2">
                                    <div className="card-free-item">
                                        <i className="bi bi-check mx-2"></i>
                                        <span>
                                            Collision Damage Waiver
                                        </span>
                                    </div>
                                </div>
                                <div className="card-free d-flex pb-2">
                                    <i className="bi bi-check mx-2"></i>
                                    <div className="card-free-item">
                                        <span>
                                            Theft Protection
                                        </span>
                                    </div>
                                </div>
                                <div className="card-free d-flex pb-2">
                                    <i className="bi bi-check mx-2"></i>
                                    <div className="card-free-item">
                                        <span>
                                            Third Party Protection
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="card-column card-prices">
                                <div className="d-flex flex-column">
                                    {results[i].sticker.toLowerCase() == "best deal" ?
                                        <div className="car-status car-status__best-deal ms-auto mb-3">
                                            <i className="bi bi-star-fill"></i>
                                            <span>
                                                BEST DEAL
                                            </span>
                                        </div>
                                        : ""
                                    }
                                    {results[i]._count.vehicles == 1 ?
                                        <div className="car-status car-status__last-one ms-auto mb-3">
                                            <i className="bi bi-star-fill"></i>
                                            <span>
                                                LAST ONE
                                            </span>
                                        </div>
                                        : ""
                                    }
                                </div>

                                <div className="card-price text-end">
                                    &#8364;{Math.ceil(results[i].prices.total)}
                                </div>
                                <div className="card-price-total text-end">
                                    Total price for {duration} days
                                </div>
                                <div className="card-book mt-2">
                                    <Link
                                        to={`/${params.lang}/price/step-3${location.search}&id=${results[i].id}`}
                                        className="btn btn-primary fw-bold w-100" type="submit" >
                                        Book Now
                                    </Link>
                                </div>
                                <div className="card-payment text-center pt-2">
                                    THEN PAY ON ARRIVAL
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (carsTemp.length > 0) {
            setCars(carsTemp);
        }

        setShowCarsPlaceholder(false);
    }

    let getPlaceholders = async () => {
        console.log("getPlaceholders")
        let placeholderTemp = [];
        for (let i = 0; i < 4; i++) {
            placeholderTemp.push(
                <div key={"key-car-placeholder-" + i} className="col-12">
                    <div className="card card__car card__car--placeholder">
                        <div className="card-body d-flex">
                            <div className="card-column">
                                <div className="card-img">
                                    <div className="load-wraper">
                                        <div className="activity"></div>
                                    </div>
                                </div>
                                <div className="card-extras d-none d-lg-block m-2">
                                    <div className="load-wraper">
                                        <div className="activity"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-column">
                                <h5 className="card-title">
                                    <div className="load-wraper">
                                        <div className="activity"></div>
                                    </div>
                                </h5>
                            </div>
                            <div className="card-column card-details">
                                <div className="load-wraper">
                                    <div className="activity"></div>
                                </div>
                            </div>
                            <div className="card-column">
                                <div className="load-wraper">
                                    <div className="activity"></div>
                                </div>
                            </div>
                            <div className="card-column card-prices">
                                <div className="load-wraper">
                                    <div className="activity"></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            );
        }

        if (placeholderTemp.length > 0) {
            setPlaceholder(placeholderTemp);
        }
    }

    return (<>
        <Helmet>
            <title>Choose your vehicle</title>
            <meta name="description" content="OLA Mauritius Car Rental is an established car hire company that offers modern rental cars at a reasonable price, with Free GPS and Transfer." />
            <meta name="keywords" content="Rent a car, Car Rental, Hire Car" />
        </Helmet>
        <MetaTags title='Step 2: Vehicles Result - OLA Mauritius' url={`results/${location.search}`}/>
        <div className='results-container container-xxl'>

            <div className="container-xxl cars-header gap-4">
                {/* {isFormInEdit ?
                    <div className="row">
                        <div className="col-12 mb-3 p-0">

                            <div className="collapse" id="searchFormToggle">
                                <div className="card">
                                    <div className="card-body">
                                        <SearchForm />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : */}
                <div className="row">
                    <div className="col-12 mb-3 p-0">
                        <div className="card">
                            <div className="card-body">

                                <div className="cars-header-title pb-4 bold">
                                    1. SEARCH RESULTS
                                </div>
                                {!location.search ?
                                    <div className="row">
                                        Please change criterias of search.
                                    </div>
                                    :
                                    <div className="row">
                                        <div className="col-12 col-xl-5">
                                            <div className="d-flex pb-2">
                                                <i className="bi bi-geo-alt-fill px-3"></i>

                                                <div className="pb-2">
                                                    <span className="pick-up-label d-block">
                                                        Pick-up
                                                    </span>
                                                    <span className="address">
                                                        {pickupLoc}
                                                        {/* Sir Seewoosagur Ramgoolam Airport */}
                                                    </span>

                                                    <div className="time d-flex ">
                                                        <span className="d-block">
                                                            {pickupDate}
                                                        </span>
                                                        <span className="d-block ms-4">
                                                            {pickupTime}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-5">
                                            <div className="d-flex pb-2">
                                                <i className="bi bi-geo-alt-fill px-3"></i>

                                                <div className="pb-2">
                                                    <span className="pick-up-label d-block">
                                                        Drop-off
                                                    </span>
                                                    <span className="address">
                                                        {diffLoc ? dropOffLoc : pickupLoc}
                                                        {/* Sir Seewoosagur Ramgoolam Airport */}
                                                    </span>

                                                    <div className="time d-flex">
                                                        <span className="d-block">
                                                            {dropOffDate}
                                                        </span>
                                                        <span className="d-block ms-4">
                                                            {dropOffTime}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-2 pt-3">
                                            {/* <button className="btn btn-outline-dark px-4 w-100" onClick={() => { setIsFormInEdit(!isFormInEdit) }}>
                                                Edit
                                            </button> */}

                                            <button
                                                className="btn btn-outline-dark px-4 w-100 semi-bold"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#searchFormToggle"
                                                aria-controls="searchFormToggle"
                                                aria-expanded="false"
                                                aria-label="Toggle Search Form"
                                                onClick={() => { setIsFormInEdit(!isFormInEdit) }}
                                                disabled={isFormInEdit}
                                            >
                                                Edit
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 mb-3 p-0">

                        <div className="collapse" id="searchFormToggle">
                            <div className="card">
                                <div className="card-body">
                                    <SearchForm />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* } */}
            </div>

            <h2 className="py-2 bold">
                Choose your vehicle
            </h2>

            <div className="cars-list-header py-3 d-flex flex-wrap gap-4">
                <div className="cars-list-counts">
                    {cars?.length ? cars.length : "0"} vehicles available
                </div>
                <div className="d-flex flex-wrap gap-4 ms-auto">
                    {transmissionsFilterSelect}
                    {categoryFilterSelect}
                    {/* <select className="form-select custom-select select-filters font-weight-bold"
                        aria-label="Select Transmissions"
                        value={transmissionsFilter} onChange={(e) => setTransmissionsFilter(e.target.value)}>
                        <option defaultValue value="">All Transmissions</option>
                        <option value="Automatic">Automatic</option>
                        <option value="Manual">Manual</option>
                    </select>
                    <select className="form-select custom-select select-filters font-weight-bold"
                        aria-label="Select Vehicle Category"
                        value={vehicleCategory} onChange={(e) => setVehicleCategory(e.target.value)}>
                        <option defaultValue value="">All Vehicle Category</option>
                        <option value="Economy">Economy</option>
                        <option value="Compact">Compact</option>
                        <option value="MidSize">MidSize</option>
                        <option value="SUV Premium">SUV Premium</option>
                        <option value="MPV 7 seater">MPV 7 seater</option>
                        <option value="COMPACT SUV">COMPACT SUV</option>
                        <option value="Convertible">Convertible</option>
                        <option value="Mini">Mini</option>
                        <option value="MPV 7 seater">MPV 7 seater</option>
                        <option value="Pick Up 4 x 4">Pick Up 4 x 4</option>
                        <option value="Premium">Premium</option>
                    </select> */}
                </div>
            </div>

            <section className="cars-section pt-4 pb-5 mb-5">
                <div className="container p-0">

                    {!!showCarsPlaceholder ?
                        <div className="row gap-4">
                            {placeholder}
                        </div>
                        :
                        < div className="row gap-4">
                            {cars}
                        </div>
                    }
                </div>
            </section>

        </div>
    </>);
}

export default Results;