import React from 'react';

function Faqs() {
    return (
        <section className="faq-section bg-primary py-4">
            <div className="container">
                <div className="row pb-5">
                    <div className="col-12 col-lg-6 pt-5">
                        <h2 className="bold">
                            Frequently Asked <br /> Questions
                        </h2>
                        <p className="m-0">
                            Still can’t find an answer to your question?
                        </p>
                        <a href="#" className="btn text-secondary text-decoration-underline p-0 semi-bold">
                            Contact us
                        </a>
                    </div>
                    <div className="col-12 col-lg-6 pt-5">
                        <div className="accordion accordion__list" id="accordion-faq">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="accordion-faq-1-heading">
                                    <button className="accordion-button bold" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-faq-1" aria-expanded="true" aria-controls="accordion-faq-1">
                                        How old do I have to be to rent a car in Mauritius?
                                    </button>
                                </h2>
                                <div id="accordion-faq-1" className=" accordion-collapse collapse show" aria-labelledby="accordion-faq-1-heading" data-bs-parent="#accordion-faq">
                                    <div className="accordion-body">
                                        <p>
                                            To rent a car, drivers must be at least 22 years old. Those under 26 will incur a "young driver" fee.
                                        </p>
                                        <p>
                                            On the other end of the spectrum, while we accommodate drivers up to 80 years old, those aged 70 and above will be subject to a "senior driver" fee.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="accordion-faq-2-heading">
                                    <button className="accordion-button collapsed bold" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-faq-2" aria-expanded="false" aria-controls="accordion-faq-2">
                                        Do I have to pay a deposit for my rental car?
                                    </button>
                                </h2>
                                <div id="accordion-faq-2" className=" accordion-collapse collapse" aria-labelledby="accordion-faq-2-heading" data-bs-parent="#accordion-faq">
                                    <div className="accordion-body">
                                        <p>
                                            For your rental car, a deposit amounting to 25% of the total rental price is required to secure your reservation. In the final step of the booking process, you'll be asked to enter your personal and other relevant details.
                                        </p>
                                        <p>
                                            The remaining 75% can be paid upon vehicle delivery, using either cash or credit card.
                                        </p>
                                        <p>
                                            Please note that credit card payments are subject to a 3% bank transaction fee.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="accordion-faq-3-heading">
                                    <button className="accordion-button collapsed bold" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-faq-3" aria-expanded="false" aria-controls="accordion-faq-3">
                                        Is an additional driver free of charge?
                                    </button>
                                </h2>
                                <div id="accordion-faq-3" className=" accordion-collapse collapse" aria-labelledby="accordion-faq-3-heading" data-bs-parent="#accordion-faq">
                                    <div className="accordion-body">
                                        <p>
                                            Absolutely! An additional driver is free of charge. We understand the need for flexibility during your travels. That's why we offer the option to include a second driver at no additional cost.
                                        </p>
                                        <p>
                                            This feature is already incorporated into your rental package, ensuring that you and your companion can share the driving responsibilities without any extra charges.
                                        </p>
                                        <p>
                                            It's just one of the ways we aim to provide a seamless and accommodating rental experience for our clients.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="accordion-faq-4-heading">
                                    <button className="accordion-button collapsed bold" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-faq-4" aria-expanded="false" aria-controls="accordion-faq-4">
                                        How do I receive and return my rental car in Mauritius?
                                    </button>
                                </h2>
                                <div id="accordion-faq-4" className=" accordion-collapse collapse" aria-labelledby="accordion-faq-4-heading" data-bs-parent="#accordion-faq">
                                    <div className="accordion-body">
                                        <p>
                                            How do I receive and return my rental car in Mauritius?
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="accordion-faq-5-heading">
                                    <button className="accordion-button collapsed bold" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-faq-5" aria-expanded="false" aria-controls="accordion-faq-5">
                                        How much does it cost to rent a car in Mauritius?
                                    </button>
                                </h2>
                                <div id="accordion-faq-5" className=" accordion-collapse collapse" aria-labelledby="accordion-faq-5-heading" data-bs-parent="#accordion-faq">
                                    <div className="accordion-body">
                                        <p>
                                            The average cost to rent a car in Mauritius is around $25 or Rs 1,100 per day for a small economy car, though this can vary depending on the car make and size, rental duration, and time of year. Additional charges may apply for extras like full coverage insurance, GPS, or baby seats, as well as for younger drivers or different drop-off locations
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="accordion-faq-6-heading">
                                    <button className="accordion-button collapsed bold" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-faq-6" aria-expanded="false" aria-controls="accordion-faq-6">
                                        How much does it cost to rent a car for a week in Mauritius?
                                    </button>
                                </h2>
                                <div id="accordion-faq-6" className=" accordion-collapse collapse" aria-labelledby="accordion-faq-6-heading" data-bs-parent="#accordion-faq">
                                    <div className="accordion-body">
                                        <p>
                                            On average, renting a car in Mauritius for a week costs around $235 ($34 per day)
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="accordion-faq-7-heading">
                                    <button className="accordion-button collapsed bold" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-faq-7" aria-expanded="false" aria-controls="accordion-faq-7">
                                        How much does it cost to rent a car long term for a month in Mauritius?
                                    </button>
                                </h2>
                                <div id="accordion-faq-7" className=" accordion-collapse collapse" aria-labelledby="accordion-faq-7-heading" data-bs-parent="#accordion-faq">
                                    <div className="accordion-body">
                                        <p>
                                            For a longer term, such as a month, the average cost is approximately $1,432 ($48 per day)
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="accordion-faq-8-heading">
                                    <button className="accordion-button collapsed bold" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-faq-8" aria-expanded="false" aria-controls="accordion-faq-8">
                                        What is driving like in Mauritius?
                                    </button>
                                </h2>
                                <div id="accordion-faq-8" className=" accordion-collapse collapse" aria-labelledby="accordion-faq-8-heading" data-bs-parent="#accordion-faq">
                                    <div className="accordion-body">
                                        <p>
                                            In Mauritius, driving is on the left side of the road, and it's mandatory for both front and rear occupants of the car to wear seatbelts. The roads are generally well-maintained, and Google Maps can be quite reliable for navigation. Local drivers are usually friendly and helpful if you need directions
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="accordion-faq-9-heading">
                                    <button className="accordion-button collapsed bold" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-faq-9" aria-expanded="false" aria-controls="accordion-faq-9">
                                        What are the speed limits in Mauritius?
                                    </button>
                                </h2>
                                <div id="accordion-faq-9" className=" accordion-collapse collapse" aria-labelledby="accordion-faq-9-heading" data-bs-parent="#accordion-faq">
                                    <div className="accordion-body">
                                        <p>
                                            The speed limits are up to 80 km/h (50 mph) in the countryside, 40 km/h (25 mph) in urban areas, and up to 100 km/h (62 mph) on highways
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="accordion-faq-10-heading">
                                    <button className="accordion-button collapsed bold" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-faq-10" aria-expanded="false" aria-controls="accordion-faq-10">
                                        What documents do I need to rent a car in Mauritius?
                                    </button>
                                </h2>
                                <div id="accordion-faq-10" className=" accordion-collapse collapse" aria-labelledby="accordion-faq-10-heading" data-bs-parent="#accordion-faq">
                                    <div className="accordion-body">
                                        To rent a car, you need a valid driver’s licence (plus an International Driving Permit if your licence isn't in Latin letters), a credit or debit card for the security deposit, and your passport. The legal driving age is 18, but most rental companies rent only to those over 21, and additional fees may apply for drivers under 24
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="accordion-faq-11-heading">
                                    <button className="accordion-button collapsed bold" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-faq-11" aria-expanded="false" aria-controls="accordion-faq-11">
                                        Can I book a long-term car rental in Mauritius?
                                    </button>
                                </h2>
                                <div id="accordion-faq-11" className=" accordion-collapse collapse" aria-labelledby="accordion-faq-11-heading" data-bs-parent="#accordion-faq">
                                    <div className="accordion-body">
                                        <p>
                                            Yes, you can book long-term car rentals in Mauritius. The rates are generally more economical for longer durations
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="accordion-faq-12-heading">
                                    <button className="accordion-button collapsed bold" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-faq-12" aria-expanded="false" aria-controls="accordion-faq-12">
                                        Do I need to purchase insurance or protection packages with my rental in Mauritius?
                                    </button>
                                </h2>
                                <div id="accordion-faq-12" className=" accordion-collapse collapse" aria-labelledby="accordion-faq-12-heading" data-bs-parent="#accordion-faq">
                                    <div className="accordion-body">
                                        Basic insurance covering damage and theft is usually included in the rental cost. However, purchasing additional insurance for extra peace of mind is recommended, especially considering the potential deductibles and exclusions in the basic coverage. At OLA Mauritius our cars are recent and in excellent roadworthy condition with fully comprehensive insurance cover
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default Faqs;