import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { RangePicker } from "react-trip-date";
import { createPortal } from 'react-dom'

import dayjs from 'dayjs';

import lang from "../lang/lang.json";
import TimePicker from './TimePicker';
import CalendarModal from './CalendarModal';

const theme = {
    primary: {
        light: "#f4f4f4",
        main: "#EC6E00",
        dark: "#EC6E00"
    },
    grey: {
        700: "#707070",
        900: "#1b1b1d"
    },
    background: {
        default: "#f5f5f5"
    },
    text: {
        disabled: "#BABABA"
    }
};

function Calendar(props) {
    const params = useParams();
    const datesData = props.datesData;
    const timesData = props.timesData;
    const pickUpDateLabel = lang[params.lang]?.searchForm.PickUpDate || "Pick up date";
    const returnDateLabel = lang[params.lang]?.searchForm.dropoffDate || "Return date";
    const [selectedDays, setSelectedDays] = useState({
        from: "",
        to: ""
    });
    const [pickUpDate, setPickUpDate] = useState(false);
    const [pickUpTime, setPickUpTime] = useState('12:00');
    // const [pickUpTime, setPickUpTime] = useState(new Date().toISOString().substring(11, 16));
    const [returnDate, setReturnUpDate] = useState(false);
    const [returnTime, setReturnTime] = useState('12:00');
    const [selectedPickUpTime, setSelectedPickUpTime] = useState();
    const [selectedReturnTime, setSelectedReturnTime] = useState();
    const [startCalendarVisible, setStartCalendarVisible] = useState(false)
    const [endCalendarVisible, setEndCalendarVisible] = useState(false)
    const [showCalendarModal, setShowCalendarModal] = useState(false)
    const startDatePickerInputRef = useRef()
    const endDatePickerInputRef = useRef()
    const bigScreen = window.innerWidth >= 1200

    useEffect(()=>{
        props.getDates(selectedDays)        
    },[selectedDays, props])

    useEffect(() => {
        console.log("timesData: ", timesData)
        if (!timesData.pickUpTime || !timesData.returnTime) {
            return;
        }
        setSelectedPickUpTime(dayjs('2022-04-17T' + timesData.pickUpTime));
        setSelectedReturnTime(dayjs('2022-04-17T' + timesData.returnTime));

    }, [timesData]);

    useEffect(() => {
        console.log("pickUpTime: ", pickUpTime)
        props.getTimes({
            pickUpTime: pickUpTime,
            returnTime: returnTime,
        })
    }, [pickUpTime, returnTime]);

    const startDateHandler = (date) => {
        if(date.from < selectedDays.to && selectedDays.from !== selectedDays.to){
            setSelectedDays(prevState => {
                return {
                    from: date.from,
                    to: prevState.to
                }
            })
        }
        else{
            setSelectedDays({
                from: date.from,
                to: date.from
            })
        }
        startDatePickerInputRef.current.click()
    }

    const endDateHandler = (date) => {

        switch(true){
            case selectedDays.from === '':
                setSelectedDays({
                        from: date.from,
                        to: date.from
                    })
                break;
            case date.from > selectedDays.from:
                setSelectedDays(prevState => {
                    return {
                        from: prevState.from,
                        to: date.from
                    }
                })
                break;
            case date.from < selectedDays.from:
                setSelectedDays(prevState => {
                    return {
                        from: date.from,
                        to: prevState.from
                    }
                })
                break;
            case selectedDays.from === selectedDays.to && date.from > selectedDays.from:
                setSelectedDays(prevState => {
                    return {
                        from: prevState.from,
                        to: date.from
                    }
                })
                break;
            case selectedDays.from === selectedDays.to && date.from < selectedDays.from:
                setSelectedDays(prevState => {
                    return {
                        from: date.from,
                        to: prevState.from
                    }
                })
                break;
            case date.from === selectedDays.from:
                setSelectedDays({
                        from: date.from,
                        to: date.from
                    })
                break;
            default: return;
        }
        setStartCalendarVisible(false)
        endDatePickerInputRef.current.click()
    }

    const customDaysComponent = ({day}) => {
        let customDay = day.split('-')[2]
        customDay = customDay[0] === '0' ? customDay[1] : customDay
        return customDay
    }

    const dateRangePicker = (onChangeHandler) => {
        return (<RangePicker
            numberOfMonths={3}
            disabledBeforeToday
            autoResponsive={false}
            theme={theme}
            disabledBeforToday={true}
            handleChange={(res) => console.log("ress>>>", res)}
            onChange={onChangeHandler}
            selectedDays={selectedDays}
            components={{
                days: customDaysComponent,
            }}
        />)
    }
    
    const showStartCalendarHandler = () => {
            if(bigScreen){
                startDatePickerInputRef.current.classList.toggle('clicked')
                if(endCalendarVisible){
                    endDatePickerInputRef.current.click()
                    setEndCalendarVisible(false)
                }
                setStartCalendarVisible(prevState=> !prevState)
            }
            else{
                setShowCalendarModal(true)
            }
    }
    
    const showEndCalendarHandler = () => {
            if(bigScreen){
                endDatePickerInputRef.current.classList.toggle('clicked')
                if(startCalendarVisible){
                    startDatePickerInputRef.current.click()
                    setStartCalendarVisible(false)
                }
                setEndCalendarVisible(prevState=> !prevState)
            }
            else{
                setShowCalendarModal(true)
            }
    }

    return (<>
        <div className="col-xl-3 pt-xl-4">
            <label htmlFor="pickupdate" className="">
                {lang[params.lang]?.searchForm.PickUpDateAndTime || "Pick up date & time"}
            </label>
            <div className={"datetime-input-wrp d-flex " + (datesData === "" ? "datetime-input-wrp--placeholder" : "")}>
                <svg className="input__icon" width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.199 0.934C4.19955 0.418395 4.61739 0.000551155 5.133 0C5.649 0 6.06745 0.418004 6.068 0.934V1.819C6.068 1.86208 6.10292 1.897 6.146 1.897H11.736C11.7791 1.897 11.814 1.86208 11.814 1.819V0.934C11.8371 0.434957 12.2484 0.0422319 12.748 0.0422319C13.2476 0.0422319 13.6589 0.434957 13.682 0.934V1.819C13.682 1.86208 13.7169 1.897 13.76 1.897H15.934C17.0083 1.8981 17.8789 2.76871 17.88 3.843V17.983C17.8789 19.0573 17.0083 19.9279 15.934 19.929H1.946C0.873 19.929 0 19.045 0 17.958V3.843C0.0011024 2.76871 0.871711 1.8981 1.946 1.897H4.121C4.16408 1.897 4.199 1.86208 4.199 1.819V0.934ZM6.145 3.766H11.76C11.803 3.766 11.838 3.8 11.838 3.843V5.234C11.8611 5.73304 12.2724 6.12577 12.772 6.12577C13.2716 6.12577 13.6829 5.73304 13.706 5.234V3.844C13.706 3.8 13.741 3.766 13.784 3.766H15.959C16.002 3.766 16.037 3.8 16.037 3.843V7.41C16.0365 7.45269 16.0017 7.487 15.959 7.487H1.946C1.90331 7.487 1.86855 7.45269 1.868 7.41V3.843C1.868 3.8 1.903 3.766 1.946 3.766H4.12C4.163 3.766 4.198 3.8 4.198 3.843V5.234C4.2211 5.73304 4.63242 6.12577 5.132 6.12577C5.63158 6.12577 6.0429 5.73304 6.066 5.234V3.844C6.066 3.8 6.101 3.766 6.144 3.766H6.145ZM16.012 17.958C16.0115 18.0007 15.9767 18.035 15.934 18.035H2.004C1.98229 18.0582 1.94864 18.066 1.91895 18.0546C1.88927 18.0431 1.8695 18.0148 1.869 17.983V9.433C1.869 9.391 1.903 9.356 1.946 9.356H15.934C15.977 9.356 16.012 9.391 16.012 9.433V17.958Z" fill="#009900"/>
                </svg>
                <input id="pickupdate"
                    className="w-100 input-wit-calendar-icon ps-5 pe-2 border-0 collapsed"
                    name="date"
                    value={!datesData || datesData.from === "" ? pickUpDateLabel : datesData.from}
                    readOnly
                    ref={startDatePickerInputRef}
                    onClick={showStartCalendarHandler}
                    type="button" data-bs-toggle="collapse" data-bs-target="#calendar-modal-start" aria-expanded="false" aria-controls="calendar-modal"
                />
                <div className="vr"></div>

                <TimePicker setHour={setPickUpTime} label='pick up' defaultTime="12:00"/>
            </div>
        </div>
        <div className="col-xl-3 pt-4">
            <label htmlFor="returndate" className="">
                {lang[params.lang]?.searchForm.dropoffDateAndTime || "Return date & time"}
            </label>
            <div className={"datetime-input-wrp d-flex " + (datesData === "" ? "datetime-input-wrp--placeholder" : "")}>
                <svg className="input__icon" width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.199 0.934C4.19955 0.418395 4.61739 0.000551155 5.133 0C5.649 0 6.06745 0.418004 6.068 0.934V1.819C6.068 1.86208 6.10292 1.897 6.146 1.897H11.736C11.7791 1.897 11.814 1.86208 11.814 1.819V0.934C11.8371 0.434957 12.2484 0.0422319 12.748 0.0422319C13.2476 0.0422319 13.6589 0.434957 13.682 0.934V1.819C13.682 1.86208 13.7169 1.897 13.76 1.897H15.934C17.0083 1.8981 17.8789 2.76871 17.88 3.843V17.983C17.8789 19.0573 17.0083 19.9279 15.934 19.929H1.946C0.873 19.929 0 19.045 0 17.958V3.843C0.0011024 2.76871 0.871711 1.8981 1.946 1.897H4.121C4.16408 1.897 4.199 1.86208 4.199 1.819V0.934ZM6.145 3.766H11.76C11.803 3.766 11.838 3.8 11.838 3.843V5.234C11.8611 5.73304 12.2724 6.12577 12.772 6.12577C13.2716 6.12577 13.6829 5.73304 13.706 5.234V3.844C13.706 3.8 13.741 3.766 13.784 3.766H15.959C16.002 3.766 16.037 3.8 16.037 3.843V7.41C16.0365 7.45269 16.0017 7.487 15.959 7.487H1.946C1.90331 7.487 1.86855 7.45269 1.868 7.41V3.843C1.868 3.8 1.903 3.766 1.946 3.766H4.12C4.163 3.766 4.198 3.8 4.198 3.843V5.234C4.2211 5.73304 4.63242 6.12577 5.132 6.12577C5.63158 6.12577 6.0429 5.73304 6.066 5.234V3.844C6.066 3.8 6.101 3.766 6.144 3.766H6.145ZM16.012 17.958C16.0115 18.0007 15.9767 18.035 15.934 18.035H2.004C1.98229 18.0582 1.94864 18.066 1.91895 18.0546C1.88927 18.0431 1.8695 18.0148 1.869 17.983V9.433C1.869 9.391 1.903 9.356 1.946 9.356H15.934C15.977 9.356 16.012 9.391 16.012 9.433V17.958Z" fill="#009900"/>
                </svg>
                <input id="returndate"
                    className="w-100 input-wit-calendar-icon ps-5 pe-2 border-0 collapsed"
                    name="name"
                    value={selectedDays.from === selectedDays.to ? returnDateLabel : datesData.to}
                    readOnly
                    ref={endDatePickerInputRef}
                    onClick={showEndCalendarHandler}
                    type="button" data-bs-toggle="collapse" data-bs-target="#calendar-modal-end" aria-expanded="false" aria-controls="calendar-modal"
                />
                <div className="vr"></div>
               <TimePicker setHour={setReturnTime} label='return' defaultTime="12:00"/>

            </div>
        </div>

       {bigScreen && 
       <>
        <div id="calendar-modal-start" className="collapse">
                <div className="ola-calendar p-4 w-100 m-auto d-none d-xl-block">
                    {dateRangePicker(startDateHandler)}
                </div>
            </div>
            <div id="calendar-modal-end" className="collapse">
                <div className="ola-calendar p-4 w-100 m-auto">
                    {dateRangePicker(endDateHandler)}
                </div>
        </div>
        </>
        }
        {!bigScreen && showCalendarModal && createPortal(
            <CalendarModal 
                onClose={()=>setShowCalendarModal(false)}
                setSelectedDays={setSelectedDays}
                dates={selectedDays}
                theme={theme}
            />
        , document.body)}
    </>);
}

export default Calendar;
