import { Fragment, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';

import logo from '../../assets/images/logo-dark.png';
import transfer from '../../assets/images/email-transfer.jpg';
import { getVoucher } from '../../services/apiCarsService';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const locations = {
    airport: 'SSR International Airport, Plaisance',
    hotel: 'Hotel Delivery',
    other: 'Other Location'
};
const currencies = {
    'EUR': "€",
    'USD': '$',
    'MUR': '₨',
    'GBP': '£',
};

export const ElementFr = ({ data, orderNum, model, pickup, dropoff, total, paymentsTotal, balance }) => {
    return (
        <table width="100%" bgcolor="#F6F6F6" style={{ backgroundColor: 'F6F6F6', fontFamily: 'Arial', color: '#333', fontSize: '16px' }} border="0">
            <tbody>
                <tr>
                    <td height="20"></td>
                </tr>
                <tr>
                    <td>
                        <table width="600" bgcolor="#ffffff" style={{ backgroundColor: '#ffffff', padding: '10px' }} border="0" align="center">
                            <tbody>
                                <tr>
                                    <td>
                                        <table style={{ marginTop: '20px' }} border="0" width="90%" align="center">
                                            <tbody>
                                                <tr>
                                                    <td height="10"></td>
                                                </tr>
                                                <tr>
                                                    <td width="50%" align="left" style={{ marginTop: '10px' }}><img src={logo} alt="OLA Mauritius" /></td>
                                                    <td width="50%" align="right" style={{ marginTop: '18px' }}><strong>Confirmation# {orderNum}</strong><br />
                                                        <span style={{ fontSize: '12px' }}>{moment(data?.createdAt).format('D MMM YYYY')}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td height="20"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td height="10" style={{ borderTop: '1px solid #f5f5f5' }}></td>
                                </tr>
                                <tr>
                                    <td>
                                        <table border="0" width="90%" align="center">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p>{data?.fullName},</p>
                                                        <p></p>
                                                        <p>Un acompte de EURO {Number(data?.priceDeposit).toFixed(2) ?? '0.00'} à &eacute;t&eacute; verse pour garantir votre r&eacute;servation. (Num&eacute;ro de re&ccedil;u: {orderNum}). Ci dessous les d&eacute;tails de votre r&eacute;servation.:</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td height="10" style={{ borderTop: '1px solid #f5f5f5' }}></td>
                                </tr>
                                <tr>
                                    <td>
                                        <table border="0" width="90%" align="center">
                                            <tbody>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <strong style={{ color: '#00B1CA', fontSize: '15px' }}>DETAILS DE LA RESERVATION</strong><br /><br />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2} align="center">
                                                        <img src={model?.photoMain} alt="" style={{ height: '175px' }} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2} align="center">
                                                        <strong>
                                                            {model?.make}
                                                        </strong> ou similaire<br />
                                                        <span style={{ color: '#333', fontSize: '12px', textTransform: 'uppercase' }}>
                                                            {(model?.gearbox === 'Automatic') ? 'Transmission Automatique' : 'Transmission Manuelle'}
                                                        </span><br />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <img src={transfer} alt="OLA Mauritius" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="50%" valign="top">
                                                        <strong>Retrait</strong><br />
                                                        {pickup}<br />
                                                        <span style={{ color: '#f27244' }}>{moment(data?.pickupDate).format('D MMM, YYYY')} à {moment(data?.pickupDate).format('H:mm')}</span><br />
                                                        {data?.flightNumber && <Fragment>Par le vol {data?.flightNumber}</Fragment>}
                                                    </td>
                                                    <td align="right" valign="top">
                                                        <strong>Retour</strong><br />
                                                        {dropoff}<br />
                                                        <span style={{ color: '#f27244' }}>{moment(data?.dropoffDate).format('D MMM, YYYY')} à {moment(data?.dropoffDate).format('H:mm')}</span><br />
                                                    </td>
                                                </tr>
                                                {/* <tr>
                                                    <td colSpan={2} align="center" style={{ paddingTop: '10px' }}>
                                                        <a href={modifyBookingLink} style={{ color: '#c3c3c3', padding: "7.5px 20px", borderRadius: '20px', border: '2px solid #c3c3c3' }}>Modify Booking</a>
                                                    </td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td height="20"></td>
                                </tr>
                                <tr>
                                    <td height="10" style={{ borderTop: '1px solid #f5f5f5' }}></td>
                                </tr>
                                <tr>
                                    <td>
                                        <table border="0" width="90%" align="center">
                                            <tbody>
                                                <tr>
                                                    <td colSpan={3}>
                                                        <strong style={{ color: '#00B1CA', fontSize: '15px' }}>DETAILS DE FACTURATION</strong><br /><br />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td align="center" style={{ border: '1px solid #f5f5f5' }}>
                                                        <strong>Montant Total</strong><br />
                                                        <span style={{ fontSize: '20px' }}>EUR {total.toFixed(2)}</span>
                                                    </td>
                                                    <td align="center" style={{ border: '1px solid #f5f5f5' }}>
                                                        <strong>Acompte Vers&eacute;</strong><br />
                                                        <span style={{ fontSize: '20px' }}>EUR {paymentsTotal.toFixed(2)}</span>
                                                    </td>
                                                    <td align="center" style={{ border: '1px solid #f5f5f5' }}>
                                                        <strong><span style={{ color: '#C0392B' }}>Montant D&ucirc; *</span></strong><br />
                                                        <span style={{ fontSize: '20px', color: '#C0392B' }}>EUR {balance.toFixed(2)}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}><br /><p style={{ fontSize: '12px' }}><span style={{ color: '#C0392B' }}>*</span> Un solde de EUR {balance.toFixed(2)} payable à la prise. (Carte Bleue ou en esp&egrave;ces)</p></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td height="10" style={{ borderTop: '1px solid #f5f5f5' }}></td>
                                </tr>
                                <tr>
                                    <td>
                                        <table border="0" width="90%" align="center">
                                            <tbody>
                                                <tr>
                                                    <td style={{ fontSize: '14px' }}>
                                                        <strong style={{ color: '#00B1CA', fontSize: '15px' }}>Quelle est la prochaine &eacute;tape?</strong>
                                                        <p>- Pour aider à acc&eacute;l&eacute;rer votre r&eacute;servation et vous &eacute;viter un temps d'attente suppl&eacute;mentaire pour le traitement des documents concernant la prise en charge du v&eacute;hicule, nous vous prions de compl&eacute;ter la <a style={{ color: '#157684' }} href={`${data?.clientSiteUrl}/fr/driver-form/?id=${orderNum}&fullname=${data?.fullName}&email=${data?.email}`}>fiche du conducteur.</a>
                                                            <br /><br />- Pour r&eacute;cup&eacute;rer votre voiture veuillez vous presenter au <strong>comptoir 9, OLA Mauritius au stand de location voiture</strong>.
                                                            <br /><br />- Comme toujours, si vous avez des questions, n'h&eacute;sitez pas à nous envoyer un courriel à <a style={{ color: '#157684' }} href="mailto:info@olamauritius.com">info@olamauritius.com</a></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td height="10" style={{ borderTop: '1px solid #f5f5f5' }}></td>
                                </tr>
                                <tr>
                                    <td height="20"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="93%" bgcolor="#F6F6F6" style={{ backgroundColor: '#f6f6f6', margin: '20px' }}>
                                            <tbody>
                                                <tr>
                                                    <td align="center" style={{ textAlign: 'center', fontSize: '12px' }}>
                                                        <br /><span style={{ color: '#F27244' }}><i><b><span style={{ fontSize: '14px' }}>OLA</span></b></i></span> MAURITIUS - <a style={{ color: '#333' }} href="http://www.olamauritius.com/">olamauritius.com</a> | <a style={{ color: '#333' }} href="mailto:info@olamauritius.com">info@olamauritius.com</a> | +230 5790 0575
                                                        <br /><br />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export const Voucher = () => {
    const [data, setData] = useState({})
    const [searchParams] = useSearchParams()
    const params = useParams()
    const axiosPrivate = useAxiosPrivate()
    const orderNum = `OLA-${String(data?.id).padStart(4, '0')}`;
    const legacy = !data?.vehicle;
    const old = (data?.id < 24963) && !data?.orders?.some(o => o.product?.name === 'Full Protection');
    const model = legacy ? data?.vehicleModel : data?.vehicle?.model;
    const pickup = `${locations[data?.pickupLocation]}${data?.pickupDetail ? ` - ${data?.pickupDetail}` : ''}`;
    const dropoff = `${locations[data?.dropoffLocation]}${data?.dropoffDetail ? ` - ${data?.dropoffDetail}` : ''}`;
    const days = moment(data?.dropoffDate).diff(moment(data?.pickupDate), 'days');
    const insuranceExcess = Number(model?.insuranceExcess);
    const insuranceExtra = Number(model?.insuranceExtra);

    const calcPaymentTotal = (amount, conversion) => Number((conversion && Number(conversion) !== 1) ? (amount * conversion) : amount);
    const calcTotal = (quantity = 0, price = 0, discount = 0, conversion = 1) => calcPaymentTotal((quantity * price) - (discount ? (discount / 100 * quantity * price) : 0), conversion);
    const total = data?.orders?.reduce((r, { quantity, price, discount, conversion }) => r + calcTotal(quantity, price, discount, conversion), 0) ?? 0;
    const paymentsTotal = data?.payments?.reduce((r, { amount, conversion }) => r + calcPaymentTotal(amount, conversion), 0) ?? 0;
    const balance = total - paymentsTotal;

    useEffect(() => {
        const fetchData = async () => {
            const id = searchParams.get('id')
            const { data } = await getVoucher(axiosPrivate, id)
            setData(data)
        }
        fetchData()
    }, [])

    return (params.lang === 'fr'
        ?    <ElementFr {...{data, orderNum, model, pickup, dropoff, total, paymentsTotal, balance}} />
        :   <table width="100%" bgcolor="#F6F6F6" style={{ backgroundColor: 'F6F6F6', fontFamily: 'Arial', color: '#333', fontSize: '16px' }} border="0">
            <tbody>
                <tr>
                    <td height="20"></td>
                </tr>
                <tr className='voucher'>
                    <td>
                        <table width="600" bgcolor="#ffffff" style={{ backgroundColor: '#ffffff', padding: '10px' }} border="0" align="center">
                            <tbody>
                                <tr>
                                    <td>
                                        <table style={{ marginTop: '20px' }} border="0" width="90%" align="center">
                                            <tbody>
                                                <tr>
                                                    <td height="10"></td>
                                                </tr>
                                                <tr>
                                                    <td width="50%" align="left" style={{ marginTop: '10px' }}><img src={logo} alt="OLA Mauritius" /></td>
                                                    <td width="50%" align="right" style={{ marginTop: '18px' }}><strong>CONFIRMATION# {orderNum}</strong><br />
                                                        <span style={{ fontSize: '12px' }}>{moment(data?.createdAt).format('D MMM YYYY')}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td height="20"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td height="10" style={{ borderTop: '1px solid #f5f5f5' }}></td>
                                </tr>
                                <tr>
                                    <td>
                                        <table border="0" width="90%" align="center">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p>Dear {data?.fullName},</p>
                                                        <p></p>
                                                        <p>Your deposit payment of EURO {Number(data?.priceDeposit).toFixed(2) ?? '0.00'} for your vehicle rental in Mauritius has been paid. (receipt reference number: {orderNum}). Below is a summary of your rental:</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td height="10" style={{ borderTop: '1px solid #f5f5f5' }}></td>
                                </tr>
                                <tr>
                                    <td>
                                        <table border="0" width="90%" align="center">
                                            <tbody>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <strong style={{ color: '#00B1CA', fontSize: '15px' }}>RENTAL DETAILS</strong><br /><br />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2} align="center">
                                                        <img src={model?.photoMain} alt="" style={{ height: '175px' }} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2} align="center">
                                                        <strong>
                                                            {model?.make}
                                                        </strong> or similar<br />
                                                        <span style={{ color: '#333', fontSize: '12px', textTransform: 'uppercase' }}>
                                                            {model?.gearbox}
                                                        </span><br />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <img src={transfer} alt="OLA Mauritius" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="50%" valign="top">
                                                        <strong>Pickup</strong><br />
                                                        {pickup}<br />
                                                        On <span style={{ color: '#f27244' }}>{moment(data?.pickupDate).utc().format('D MMM, YYYY')} at {moment(data?.pickupDate).utc().format('H:mm')}</span><br />
                                                        {data?.flightNumber && <Fragment>via flight {data?.flightNumber}</Fragment>}
                                                    </td>
                                                    <td align="right" valign="top">
                                                        <strong>Dropoff</strong><br />
                                                        {dropoff}<br />
                                                        On <span style={{ color: '#f27244' }}>{moment(data?.dropoffDate).utc().format('D MMM, YYYY')} at {moment(data?.dropoffDate).utc().format('H:mm')}</span><br />
                                                    </td>
                                                </tr>
                                                {/* <tr>
                                                    <td colSpan={2} align="center" style={{ paddingTop: '10px' }}>
                                                        <a href={modifyBookingLink} style={{ color: '#c3c3c3', padding: "7.5px 20px", borderRadius: '20px', border: '2px solid #c3c3c3' }}>Modify Booking</a>
                                                    </td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td height="20"></td>
                                </tr>
                                <tr>
                                    <td height="10" style={{ borderTop: '1px solid #f5f5f5' }}></td>
                                </tr>
                                <tr>
                                    <td>
                                        <table border="0" width="90%" align="center">
                                            <tbody>
                                                <tr>
                                                    <td colSpan={3}>
                                                        <strong style={{ color: '#00B1CA', fontSize: '15px' }}>PAYMENT DETAILS</strong><br /><br />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}>
                                                        <table border="0" width="100%" cellPadding={0} cellSpacing={0} style={{ fontSize: '14px', lineHeight: '35px' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th align="left" style={{ backgroundColor: '#f0f0f0' }}>Item</th>
                                                                    <th style={{ backgroundColor: '#f0f0f0', textAlign: 'center' }}>Quantity</th>
                                                                    <th style={{ backgroundColor: '#f0f0f0', textAlign: 'right' }}>Unit Price</th>
                                                                    <th style={{ backgroundColor: '#f0f0f0', textAlign: 'right' }}>Total</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data?.orders?.map(o => {
                                                                    return (
                                                                        <tr key={o.id}>
                                                                            <td style={{ borderBottom: '1px dotted #cecece' }} align="left">{o.product?.name}</td>
                                                                            <td style={{ borderBottom: '1px dotted #cecece' }} align="center">{o.quantity}</td>
                                                                            <td style={{ borderBottom: '1px dotted #cecece' }} align="right">{currencies[o.currency] ?? '€'} {Number(o.price ?? 0).toFixed(2)}</td>
                                                                            <td style={{ borderBottom: '1px dotted #cecece' }} align="right">€ {calcTotal(o.quantity, o.price, o.discount, o.conversion).toFixed(2)}</td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                                <tr>
                                                                    <td style={{ backgroundColor: '#fff', border: '0px', borderColor: '#fff' }}></td>
                                                                    <td style={{ backgroundColor: '#fff', border: '0px', borderColor: '#fff' }}></td>
                                                                    <td style={{ backgroundColor: '#e9e9e9', borderBottom: '1px solid #cecece' }} align="right"><strong>GRAND TOTAL</strong></td>
                                                                    <td style={{ backgroundColor: '#e9e9e9', borderBottom: '1px solid #cecece' }} align="right"><strong>€ {total.toFixed(2)}</strong></td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ backgroundColor: '#fff', borderBottom: '1px solid #fff' }}></td>
                                                                    <td style={{ backgroundColor: '#fff', borderBottom: '1px solid #fff' }}></td>
                                                                    <td style={{ backgroundColor: '#fff', borderBottom: '1px solid #cecece' }} align="right"><i>Deposit / Payments</i></td>
                                                                    <td style={{ backgroundColor: '#fff', borderBottom: '1px solid #cecece' }} align="right"><i>€ {paymentsTotal.toFixed(2)}</i></td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ backgroundColor: '#fff', border: '0px', borderColor: '#fff' }}></td>
                                                                    <td style={{ backgroundColor: '#fff', border: '0px', borderColor: '#fff' }}></td>
                                                                    <td style={{ backgroundColor: '#f9f9f9', borderBottom: '1px solid #cecece', color: '#C0392B' }} align="right">Balance</td>
                                                                    <td style={{ backgroundColor: '#f9f9f9', borderBottom: '1px solid #cecece', color: '#C0392B' }} align="right">€ {balance.toFixed(2)}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}><br /><p style={{ fontSize: '12px' }}><span style={{ color: '#C0392B' }}>*</span> Remaining balance of € {balance.toFixed(2)} to be paid on pickup. You can pay in any equivalent currency or using your credit card. (For credit card settlements, a 4% charge will be applied)</p></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td height="10" style={{ borderTop: '1px solid #f5f5f5' }}></td>
                                </tr>
                                <tr>
                                    <td>
                                        <table border="0" width="90%" align="center">
                                            <tbody>
                                                <tr>
                                                    <td style={{ fontSize: '14px' }}>
                                                        <strong style={{ color: '#00B1CA', fontSize: '15px' }}>WHAT'S NEXT?</strong>
                                                        <p>- To help expedite your reservation and prevent additional waiting time for document processing on your pickup, please <a style={{ color: '#157684' }} href={`/${params.lang ?? 'en'}/driver-form/?id=${orderNum}&fullname=${data?.fullName}&email=${data?.email}`}>complete the driver profile form.</a>
                                                            <br /><br /> - If you are picking up your vehicle from the airport, please proceed to the <strong>OLA Mauritius Counter 9 located in the Car Rental Booth</strong> at the airport.
                                                            <br /><br /> - As always, if you have any questions, feel free to email us at <a style={{ color: '#157684' }} href="mailto:info@olamauritius.com">info@olamauritius.com</a></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td height="10" style={{ borderTop: '1px solid #f5f5f5' }}></td>
                                </tr>
                                {(old || model?.zeroExcess || (model?.zeroExcess === 'No')) && <tr>
                                    <td>
                                        <table border="0" width="90%" align="center">
                                            <tbody>
                                                <tr>
                                                    <td style={{ fontSize: '14px' }}>
                                                        <strong style={{ color: '#00B1CA', fontSize: '15px' }}>ABOUT YOUR INSURANCE</strong>
                                                        <p>All rentals at OLA Mauritius come along with an "All Comprehensive Insurance Cover" which include CDW (Collision Damage Waiver ) and TP (Theft Protection). You therefore limit your financial liability to the deductible or excess amount. In cases where damage is less than the excess we then have a gentleman agreement and simply request you to make good to the damages at an approximate rate. However you'll not be covered by our insurance in cases of drunken driving or gross negligence. To be noted that we will block the excess amount on your credit card as a deposit. If you return the vehicle undamaged, the deposit will be released immediately.</p>
                                                        <p>Deductible /excess amount for this car: <strong>EUR {insuranceExcess.toFixed(2)}</strong></p>
                                                        {(old && (days >= 5)) && <Fragment>
                                                            <p><strong>Insurance waivers or extra insurance :</strong></p>
                                                            <p>At OLA you can also opt to reduce the excess to half by paying an additional daily rate to your normal rental charges but which is non refundable. This simply means that if any damage occurs to your rented vehicle you won't be liable to pay the total deductible or excess as you already chose to go for an extra insurance. To be noted that we&apos;ll block half the excess amount on your credit card. However extra insurance excludes the following: Accidental damage to windows, tyres, undercarriage, and roof, Loss of keys/personal belongings, Extras like GPS or Baby seat.</p>
                                                            <p><strong>Extra insurance cost: EUR {(insuranceExtra * days).toFixed(2)}</strong> (EUR {insuranceExtra} per day)</p>
                                                            <p><strong>Reduced Exccess amount: EUR {(insuranceExcess / 2).toFixed(2)}</strong></p>
                                                        </Fragment>}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>}
                                <tr>
                                    <td height="20"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="93%" bgcolor="#F6F6F6" style={{ backgroundColor: '#f6f6f6', margin: '20px' }}>
                                            <tbody>
                                                <tr>
                                                    <td align="center" style={{ textAlign: 'center', fontSize: '12px' }}>
                                                        <br /><span style={{ color: '#F27244' }}><i><b><span style={{ fontSize: '14px' }}>OLA</span></b></i></span> MAURITIUS - <a style={{ color: '#333' }} href="http://www.olamauritius.com/">olamauritius.com</a> | <a style={{ color: '#333' }} href="mailto:info@olamauritius.com">info@olamauritius.com</a> | +230 5790 0575
                                                        <br /><br />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};


export default Voucher