// const API_URL = process.env.REACT_APP_API;
import reviews from "./../api/data/json/reviews.json";

async function getAllReviews(axiosPrivate) {
    // let results = axiosPrivate.post(
    //     `${API_URL}/review`,
    //     {}
    // );

    // return results;

    return {
        data: [
            {
                reviewer: "Pesho",
                title: "Julian Gruber 1",
                text: "Laudem et via procedat oratio quaerimus igitur, inquit, sic agam, ut ita ruant itaque aiunt hanc quasi involuta aperiri, altera occulta quaedam et impetus quo pertineant non quo aut odit aut quid et dolorem? sunt autem quibusdam et impetus quo minus id, de utilitatibus, nihil.",
                stars: 5
            },
            {
                reviewer: "Pesho",
                title: "Julian Gruber 2",
                text: "Laudem et via procedat oratio quaerimus igitur, inquit, sic agam, ut ita ruant itaque aiunt hanc quasi involuta aperiri, altera occulta quaedam et impetus quo pertineant non quo aut odit aut quid et dolorem? sunt autem quibusdam et impetus quo minus id, de utilitatibus, nihil.",
                stars: 3
            },
            {
                reviewer: "Pesho",
                title: "Julian Gruber 3",
                text: "Laudem et via procedat oratio quaerimus igitur, inquit, sic agam, ut ita ruant itaque aiunt hanc quasi involuta aperiri, altera occulta quaedam et impetus quo pertineant non quo aut odit aut quid et dolorem? sunt autem quibusdam et impetus quo minus id, de utilitatibus, nihil.",
                stars: 2
            },
            {
                reviewer: "Pesho",
                title: "Julian Gruber 4",
                text: "Laudem et via procedat oratio quaerimus igitur, inquit, sic agam, ut ita ruant itaque aiunt hanc quasi involuta aperiri, altera occulta quaedam et impetus quo pertineant non quo aut odit aut quid et dolorem? sunt autem quibusdam et impetus quo minus id, de utilitatibus, nihil.",
                stars: 5
            },
            {
                reviewer: "Pesho",
                title: "Julian Gruber 5",
                text: "Laudem et via procedat oratio quaerimus igitur, inquit, sic agam, ut ita ruant itaque aiunt hanc quasi involuta aperiri, altera occulta quaedam et impetus quo pertineant non quo aut odit aut quid et dolorem? sunt autem quibusdam et impetus quo minus id, de utilitatibus, nihil.",
                stars: 5
            },
            {
                reviewer: "Pesho",
                title: "Julian Gruber 6",
                text: "Laudem et via procedat oratio quaerimus igitur, inquit, sic agam, ut ita ruant itaque aiunt hanc quasi involuta aperiri, altera occulta quaedam et impetus quo pertineant non quo aut odit aut quid et dolorem? sunt autem quibusdam et impetus quo minus id, de utilitatibus, nihil.",
                stars: 5
            }
        ]
    }
}


async function fetchReviews(axiosPrivate) {
    // let results = axiosPrivate.post(
    //     `${API_URL}/Reviews`,
    //     {}
    // );

    // return results;

    return reviews;
}

export {
    getAllReviews,
    fetchReviews
}