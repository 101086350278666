import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router';
import { Link } from "react-router-dom";

import lang from "../../lang/lang.json";
import { formatDate } from '../../utils/dateUtils';
import { getBlogs } from '../../services/apiBlogsService';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import MetaTags from '../../utils/MetaTags';

function Blogs() {
    const params = useParams();
    const axiosPrivate = useAxiosPrivate();
    const [showBlogsPlaceholder, setShowBlogsPlaceholder] = useState(true);
    const [blogs, setBlogs] = useState(false);
    const [placeholder, setPlaceholder] = useState(false);

    useEffect(() => {
        getPlaceholders();
        if (!blogs.length) {
            // Timeout needs to mimic asynchrone blogs loading
            setTimeout(() => {
                getBlogList();
            }, 0);
        }
    }, []);

    let getBlogList = async () => {
        setShowBlogsPlaceholder(true);
        let results = await getBlogs(axiosPrivate);
        console.log("blogs: ", results)
        results = results?.data
        // use "en" records when there are no records for this lang param
        if (params.lang && results[params.lang]?.length > 0) {
            results = results[params.lang];
        } else {
            results = results["en"];
        }
        let blogsTemp = [];
        if (results && results.length > 0) {
            for (let i = 0; i < results.length; i++) {
                blogsTemp.push(
                    <div key={"key-blogs-" + i} className="col-12 col-lg-6 p-4">
                        <Link to={`/${params.lang}/blog/?id=${results[i].url}`} className="text-decoration-none">
                            <div className="card card__horizontal">
                                <img className="card-img" src={results[i].imgUrl} alt={results[i].imgAlt || results[i].title} width="244.26" height="216" loading="lazy"/>
                                <div className="card-body">
                                    <div className="card-date semi-bold">{results[i].timestamp && formatDate(new Date(results[i].timestamp), "DD MMM, YYYY")}</div>
                                    <h5 className="card-title bold">{results[i].title}</h5>
                                    <p className="card-text">
                                        {results[i].text}
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </div>
                );
            }

            if (blogsTemp.length > 0) {
                setBlogs(blogsTemp);
            }
        }
        setShowBlogsPlaceholder(false);
    }

    let getPlaceholders = async () => {
        let placeholderTemp = [];
        for (let i = 0; i < 4; i++) {
            placeholderTemp.push(
                <div key={"key-blogs-placeholder-" + i} className="col-12 col-lg-6 p-4">
                    <div className="card card__horizontal card__horizontal--plcaseholder">
                        <div className="card-img">
                            <div className="load-wraper">
                                <div className="activity"></div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="card-date">
                                <div className="load-wraper">
                                    <div className="activity"></div>
                                </div></div>
                            <h5 className="card-title">
                                <div className="load-wraper">
                                    <div className="activity"></div>
                                </div></h5>
                            <p className="card-text">
                                <div className="load-wraper">
                                    <div className="activity"></div>
                                </div>
                            </p>
                        </div>
                    </div>
                </div>
            );
        }

        if (placeholderTemp.length > 0) {
            setPlaceholder(placeholderTemp);
        }
    }

    const description = 'Our blog features timely, relevant and helpful information for helping you make the most of your holidays in Mauritius. We cover many topics including accomodation, car rental, activities as well as OLA Mauritius announcements and special offers.'

    return (<Fragment>
    
        <MetaTags title='OLA Mauritius Blog - OLA Mauritius' url='blog' description={description} ogDescription={description}/>

        <section className="why-section py-5">
            <div className="container">
                <div className="mx-3">
                    <h2 className="text-center pt-2 pb-4 bold">{lang[params.lang]?.pages.blogs.title || "OLA Mauritius Blog"}</h2>
                    <p>
                        {
                            lang[params.lang]?.pages.blogs.intro ||
                            "Our blog features timely, relevant and helpful information for helping you make the most of your holidays in Mauritius. We cover many topics including accomodation, car rental, activities as well as OLA Mauritius announcements and special offers."
                        }
                    </p>
                </div>

                {!!showBlogsPlaceholder ?

                    <div className="row justify-content-around">

                        {placeholder}

                    </div>
                    :
                    <div className="row justify-content-around">

                        {blogs}

                    </div>
                }
            </div>
        </section>
    </Fragment>);
}

export default Blogs;