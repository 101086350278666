import React, { useState, useEffect } from 'react';

import { getAllBlogs } from '../services/apiBlogsService';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { Link, useParams } from 'react-router-dom';

function Blogs() {
    const axiosPrivate = useAxiosPrivate();
    const [showBlogsPlaceholder, setShowBlogsPlaceholder] = useState(true);
    const [blogs, setBlogs] = useState(false);
    const [placeholder, setPlaceholder] = useState(false);
    const params = useParams();

    useEffect(() => {
        getPlaceholders();
        if (!blogs.length) {
            // Timeout needs to mimic asynchrone blogs loading
            setTimeout(() => {
                getBlogs();
            }, 0);
        }
    }, []);

    let getBlogs = async () => {
        setShowBlogsPlaceholder(true);
        let results = await getAllBlogs(axiosPrivate);
        console.log("blogs: ", results)
        results = results?.data
        let blogsTemp = [];
        if (results && results.length > 0) {
            for (let i = 0; i < results.length; i++) {
                blogsTemp.push(
                    <div key={"key-blogs-" + i} className="col-12 col-md-6 col-lg-4 py-5 text-left">
                        <div className="card card__big">
                            <img className="card-img-top" src={results[i].imgUrl} alt="Car parking" />
                            <div className="card-body">
                                <h5 className="card-title bold">{results[i].title}</h5>
                                <p className="card-text">
                                    {results[i].text}
                                </p>
                                {params.lang !== 'fr' && 
                                    <Link to={params.lang ? `/${params.lang}/${results[i].url || ""}` : `en/${results[i].url || ""}`} className="btn text-secondary bold">
                                        Read more
                                    </Link>
                                }
                            </div>
                        </div>
                    </div>
                );
            }

            if (blogsTemp.length > 0) {
                setBlogs(blogsTemp);
            }
        }
        setShowBlogsPlaceholder(false);
    }

    let getPlaceholders = async () => {
        let placeholderTemp = [];
        for (let i = 0; i < 3; i++) {
            placeholderTemp.push(
                <div key={"key-blogs-placeholder-" + i} className="col-12 col-md-6 col-lg-4 py-5 text-left">
                    <div className="card card__big card__big--plcaseholder">
                        <div className="card-img-top">
                            <div className="load-wraper">
                                <div className="activity"></div>
                            </div>
                        </div>
                        <div className="card-body">
                            <h5 className="card-title">
                                <div className="load-wraper">
                                    <div className="activity"></div>
                                </div></h5>
                            <div className="card-text">
                                <div className="load-wraper">
                                    <div className="activity"></div>
                                </div></div>
                            <div className="btn"></div>
                        </div>
                    </div>
                </div>
            );
        }

        if (placeholderTemp.length > 0) {
            setPlaceholder(placeholderTemp);
        }
    }

    return (
        <section className="why-section bg-primary py-5">
            <div className="container">

                {!!showBlogsPlaceholder ?

                    <div className="row justify-content-around">

                        {placeholder}

                    </div>
                    :
                    <div className="row justify-content-around">

                        {blogs}

                    </div>
                }
            </div>
        </section>
    );
}

export default Blogs;