import { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";

const PriceCalculator = ({ products, checkoutData }) => {

    const location = useLocation()
    const checkoutPage = location.pathname.includes("checkout")
    const params = useParams()
    const navigate = useNavigate()
    const [newPrice, setNewPrice] = useState(0)

    useEffect(()=> {
        console.log("NEW PRICE??");
        const price = checkoutData.finalPrice || ''
        let calculatedAmount = products?.price || ''
        
            for(let key in products){
                if(key !== 'price'){
                    const product = products[key].product
                    calculatedAmount += product.price * products[key].amount 
                }
            }
        setNewPrice(checkoutPage ? price : calculatedAmount)

    }, [products, checkoutPage, checkoutData])

    const goToBooking = () => {
        let options = "";
        for(let key in products){
            if(products[key].amount > 0){
                options += `id${key}value${products[key].amount}`
            }
        }

        let url = `/${params.lang}/price/checkout${location.search}&options=${options}`
        navigate(url);
    }

    return (
        <div className="price-calculator__wrapper">
            <div className="price-calculator">
                <div className="price-calculator__price--container">
                    <p className="price-calculator__label semi-bold">Total price</p>
                    <p className="price-calculator__price bold">{`€ ${Math.ceil(newPrice)}`}</p>
                </div>
                {!checkoutPage && <button className="price__calculator--btn btn btn-primary" onClick={goToBooking}>Continue</button>}
                {checkoutPage &&  <button className={`${checkoutData.booked ? "change__color--btn" : ""} price__calculator--btn btn btn-primary`} form="submitCheckout" disabled={checkoutData.bookDisabled}>{`${checkoutData.booked ? "Booked" : 'Complete'}`}</button>}
            </div>   
        </div>
    )
}

export default PriceCalculator