import home from './pages/Home';
import AirportTransfer from './pages/AirportTransfer';
import Faq from './pages/Faq';
import WhyOla from './pages/WhyOla';
import Benefits from './pages/Benefits';
import OurEnterprise from './pages/OurEnterprise';
import Results from './pages/Results';
import Reviews from './pages/Reviews';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import contact from './pages/Contact';
import blog from './pages/Blogs';
import Step3 from './pages/booking/step3';
import Checkout from './pages/booking/checkout';
import ConvertibleRental from './pages/ConvertibleRental';

import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import './assets/styles/index.scss';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";

import header from './components/Header'
import Footer from './components/Footer'
import Price from './pages/booking/Price';
import DriverProfile from './pages/booking/DriverProfile';
import Voucher from './pages/booking/Voucher';
import Final from './pages/booking/Final';

function MainLayout() {
  return (
      <div className="App">
        <header.Header />
        <Outlet />
        <Footer />
      </div>
  )
}

function App() {
  
  return (
    <Router>
      <Routes>
        <Route path="/:lang" element={<MainLayout />}>
            <Route index element={<home.Home />}/>
            <Route path="results" element={<Results />}/>
            <Route path="services/airport-transfer" element={<AirportTransfer />}/>
            <Route path="transfert-aeroport" element={<AirportTransfer />}/>
            <Route path="why-choose-us/faq" element={<Faq />}/>
            {/* <Route path="why-choose-us" element={<WhyOla />}/> */}
            <Route path="benefits" element={<Benefits />}/>
            <Route path="notre-entreprise" element={<OurEnterprise />}/>
            <Route path="privacy-policy" element={<Privacy />}/>
            <Route path="mentions-legale" element={<Privacy />}/>
            <Route path="terms-and-conditions" element={<Terms />}/>
            <Route path="conditions-generale" element={<Terms />}/>
            <Route path="price" element={<Price/>}>
              <Route path="step-3" element={<Step3 />}/>
              <Route path="checkout" element={<Checkout />}/>
            </Route>
            <Route path="final" element={<Final />}/>
            <Route path="driver-form" element={<DriverProfile />}/>
            <Route path='driver-profile-form' element={<DriverProfile/>}/>
            <Route path="contact-us" element={<contact.Contact />}/>
            <Route path="contact" element={<contact.Contact />}/>
            <Route path="testimonials" element={<Reviews />}/>
            <Route path="avis-clients" element={<Reviews />}/>
            <Route path="blogs" element={<blog.BlogList />}/>
            <Route path="blog" element={<blog.BlogDetails />}/>
            <Route path="convertible-rental" element={<ConvertibleRental />}/>
          </Route>
        <Route path="voucher" element={<Voucher />}/>
        <Route path="*" element={<Navigate to="/en" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
